import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SaveButton from "components/buttons/SaveButton";
import maintenanceRequestFields from "config/forms/maintenanceRequestFields";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import CancelButton from "components/buttons/CancelButton";
import { useNavigate } from "react-router";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useGetAllContracts } from "hooks/queries/useContracts";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import enums from "config/enums";
import ExitFormModal from "components/modals/ExitFormModal";

const MaintenanceCreateForm = forwardRef(
  (
    {
      onCreate,
      selectedAsset,
      selectedEquipment,
      hideDefaultActionButtons = false,
      preselectedEquipmentType,
    },
    ref
  ) => {
    const {
      data: maintenancesTypes,
      isLoading: isMaintenancesTypesLoading,
      error: errorMaintenancesTypes,
    } = useGetAllMaintenanceTypes({
      assetCategoryId: selectedAsset?.assetCategory?.id || null,
      equipmentTypeIds:
        selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType?.id &&
        !preselectedEquipmentType
          ? [
              selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType
                ?.id,
            ]
          : preselectedEquipmentType?.id
            ? [preselectedEquipmentType?.id]
            : null,
    });
    const {
      data: contracts,
      isLoading: isContractsLoading,
      error: errorContracts,
    } = useGetAllContracts({
      status: ["Active"],
      serviceType: ["Maintenance"],
    });

    const maintenanceStatus = Object?.entries(enums.MaintenanceStatus)?.map(
      ([key, value]) => ({
        id: key.toLowerCase(),
        label: value,
      })
    );

    const schema = yup.object(
      maintenanceRequestFields.maintenance.reduce((acc, field) => {
        let fieldSchema;

        if (field.type === "text" || field.type === "textarea") {
          fieldSchema = yup.string();
        } else if (field.type === "select" || field.type === "boolean") {
          if (field.name === "status" || field.name === "contractId") {
            fieldSchema = yup.object().shape({
              id: yup.string(),
              label: yup.string(),
            });
          } else {
            fieldSchema = yup.string();
          }
        } else if (field.type === "number") {
          fieldSchema = yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value));
        } else if (field.type === "date") {
          fieldSchema = yup.date().transform((value, originalValue) => {
            return isNaN(Date.parse(originalValue)) ? undefined : value;
          });
        } else if (field.type === "file") {
          const validTypes = field.validTypes || [];
          const maxSize = field.maxSize || 5000000;

          fieldSchema = yup.mixed().test({
            name: "fileValidation",
            exclusive: true,
            message: "Invalid file",
            test: (value) => {
              if (!value || value.length === 0) return true;

              for (const file of value) {
                if (
                  !validTypes.includes(file.type) &&
                  !validTypes.includes(file.mimeType)
                ) {
                  return new yup.ValidationError(
                    "Μη έγκυρος τύπος αρχείου",
                    null,
                    "file"
                  );
                }

                if (file.size > maxSize) {
                  return new yup.ValidationError(
                    `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                    null,
                    "file"
                  );
                }
              }

              return true;
            },
          });
        }

        if (field.required) {
          fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
        } else {
          fieldSchema = fieldSchema.nullable();
        }

        if (field.validation === "custom" && field.regex) {
          fieldSchema = fieldSchema.matches(
            field.regex,
            field.validationMessage
          );
        }

        acc[field.name] = fieldSchema.typeError(
          field.validationMessage || "Μη έγκυρη τιμή"
        );

        return acc;
      }, {})
    );

    const defaultValues = maintenanceRequestFields.maintenance.reduce(
      (acc, field) => {
        acc[field.name] = field.defaultValue;
        return acc;
      },
      {}
    );

    const {
      control,
      handleSubmit,
      formState: { errors, isDirty },
      getValues,
      reset,
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: defaultValues,
    });

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        return new Promise((resolve, reject) => {
          handleSubmit(
            (data) => {
              if (onCreate) {
                Promise.resolve(onCreate(data))
                  .then(() => {
                    resolve();
                  })
                  .catch((error) => {
                    reject(new Error(JSON.stringify(error)));
                  });
              } else {
                resolve();
              }
            },
            (errors) => {
              reject(new Error(JSON.stringify(errors)));
            }
          )();
        });
      },
    }));

    const isCreatingLoading =
      useIsMutating({ mutationKey: ["createMaintenance"] }) +
        useIsMutating({ mutationKey: ["uploadFiles"] }) +
        useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
        useIsMutating({ mutationKey: ["deleteFile"] }) >
      0;
    const navigate = useNavigate();

    if (isMaintenancesTypesLoading || isContractsLoading) {
      return <SpinningCircle />;
    }

    if (errorMaintenancesTypes || errorContracts) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <p>
            Error:
            {errorMaintenancesTypes.message || errorContracts.message}
          </p>
        </Box>
      );
    }

    const getInputElementByFieldType = (field) => {
      if (
        field.type === "text" ||
        field.type === "number" ||
        field.type === "textarea"
      ) {
        return <CustomTextField {...field} control={control} />;
      } else if (field.type === "select" || field.type === "boolean") {
        let availableOptions =
          field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

        if (field.name === "maintenanceTypeId") {
          availableOptions = maintenancesTypes?.items?.map(
            (option) => option.name
          );
        }
        if (field.name === "contractId") {
          availableOptions = contracts?.items?.map((option) => ({
            id: option.id,
            label: option.name,
          }));
        }

        if (field.name === "status") {
          availableOptions = maintenanceStatus;
        }

        return (
          <DropdownField
            {...field}
            control={control}
            options={availableOptions}
            boolean={field.type === "boolean"}
          />
        );
      } else if (field.type === "date") {
        return <DateField {...field} control={control} />;
      } else if (field.type === "file") {
        return <AlfrescoFileUploader {...field} control={control} />;
      }
    };

    const formFields = maintenanceRequestFields.maintenance;

    return (
      <>
        <form onSubmit={handleSubmit(() => onCreate(getValues()))}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Grid container spacing={2}>
              {formFields
                .filter((field) => field.display !== false)
                .map((field) => {
                  if (field.type === "file") {
                    return (
                      <Grid item xs={12} key={field?.name}>
                        <Box width={{ xs: "100%", lg: "50%" }}>
                          {getInputElementByFieldType(field)}
                        </Box>
                      </Grid>
                    );
                  } else
                    return (
                      <Grid item xs={12} md={4} key={field?.name}>
                        {getInputElementByFieldType(field)}
                      </Grid>
                    );
                })}
            </Grid>

            {Object.keys(errors).length > 0 && (
              <Typography color="error" fontSize={14}>
                Παρακαλούμε ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
              </Typography>
            )}

            {!hideDefaultActionButtons && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={2} justifyContent={"flex-start"}>
                  <CancelButton
                    onClick={() => {
                      reset();
                      navigate(-1);
                    }}
                  />
                </Box>
                <SaveButton text="Δημιουργία" isLoading={isCreatingLoading} />
              </Box>
            )}
          </Box>
        </form>

        <ExitFormModal isFormDirty={isDirty} />
      </>
    );
  }
);

MaintenanceCreateForm.propTypes = {
  onCreate: PropTypes.func,
  selectedAsset: PropTypes.object,
  selectedEquipment: PropTypes.object,
  hideDefaultActionButtons: PropTypes.bool,
  preselectedEquipmentType: PropTypes.object,
};

export default MaintenanceCreateForm;
