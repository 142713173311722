import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import { useGetAllCertificationTypes } from "hooks/queries/useCertificationType";
import SpinningCircle from "components/spinners/SpinningCircle";
import DeleteModal from "components/modals/DeleteModal";
import { useState } from "react";
import certificationResponseFields from "config/forms/certificationResponseFields";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import ExitFormModal from "components/modals/ExitFormModal";

const CertificationViewEditForm = ({
  selectedCertification,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
  facilityType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateCertification"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const isDeleteLoading =
    useIsMutating({ mutationKey: ["deleteCertification"] }) > 0;
  const { data: certificationTypes, isLoading: certificationTypesLoading } =
    useGetAllCertificationTypes({
      facilityType,
    });
  const { data: companies, isLoading: companiesLoading } = useGetAllCompanies(
    {},
    {
      select: (data) => data?.result?.items,
    }
  );

  const availableTypesOptions = certificationTypes?.items?.map(
    (item) => item.name
  );

  const availableCompaniesOptions = companies?.map(
    (company) => `${company.name} (${company.vat})`
  );

  const schema = yup.object(
    certificationResponseFields.certification.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "frequency") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const value = selectedCertification[fieldName];
    return value !== undefined && value !== null ? value : null;
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const defaultValues = {
    ...Object.values(certificationResponseFields.certification).reduce(
      (acc, field) => {
        if (field.name === "certificationTypeId") {
          acc[field.name] = certificationTypes?.items?.find(
            (item) => item.id === getDataValueFromField("certificationTypeId")
          )?.name;
        } else if (field.name === "externalCompanyId") {
          const company = companies?.find(
            (company) =>
              company.id === getDataValueFromField("externalCompanyId")
          );
          acc[field.name] = `${company?.name} (${company?.vat})`;
        } else {
          acc[field.name] = getDataValueFromField(field.name);
        }
        return acc;
      },
      {}
    ),
    files: getDataValueFromField("files")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return (
        <CustomTextField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (!availableOptions) {
        if (field.name === "certificationTypeId") {
          availableOptions = availableTypesOptions;
        } else if (field.name === "externalCompanyId") {
          availableOptions = availableCompaniesOptions;
        }
      }

      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  if (certificationTypesLoading || companiesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Grid container spacing={2}>
            {certificationResponseFields.certification
              ?.filter((field) => field.display !== false)
              .map((field) => {
                if (field.type === "file") {
                  return (
                    <Grid item xs={12} key={field?.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  );
                } else
                  return (
                    <Grid item xs={12} md={6} key={field.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  );
              })}
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                onClick={() => {
                  reset();
                  onCancel();
                }}
                disabled={!isEditing}
              />
              <DeleteButton
                onClick={handleOpenModal}
                text="Διαγραφή Πιστοποίησης"
                disabled={!isEditing}
                isLoading={isDeleteLoading}
              />
            </Box>

            <SaveButton
              disabled={!isEditing || !isDirty}
              isLoading={isUpdateLoading}
            />

            <DeleteModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={onDelete}
              itemTitle={selectedCertification?.name}
              title="Πρόκειται να διαγράψετε την πιστοποίηση"
              description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή της πιστοποίησης;"
            />
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

CertificationViewEditForm.propTypes = {
  selectedCertification: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default CertificationViewEditForm;
