import { Box } from "@mui/material";
import MassEquipmentImportUploader from "../MassEquipmentImportUploader";
import PropTypes from "prop-types";

const requiredHeaders = [
  "Τύπος",
  "Ομάδα",
  "Κλάση",
  "Όνομα",
  "Περιγραφή",
  "Όροφος",
  "Τύπος προμήθειας",
  "Ακίνητο",
];

const UploadFileStep = ({
  onUpload,
  selectedEquipmentTypeName,
  selectedAssetName,
}) => {
  const handleFileUpload = (json) => {
    onUpload(json);
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <MassEquipmentImportUploader
        onFileUpload={handleFileUpload}
        requiredHeaders={requiredHeaders}
        selectedEquipmentTypeName={selectedEquipmentTypeName}
        selectedAssetName={selectedAssetName}
      />
    </Box>
  );
};

UploadFileStep.propTypes = {
  onUpload: PropTypes.func.isRequired,
  selectedEquipmentTypeName: PropTypes.string,
  selectedAssetName: PropTypes.string,
};

export default UploadFileStep;
