import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography, Box, Stack } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import colors from "config/theme/colors";
import { useNavigate } from "react-router";
import { useCustomNavigation } from "components/providers/NavigationProvider";

const ExitFormModal = ({ isFormDirty }) => {
  const [showExitModal, setShowExitModal] = useState(false);
  const [navigationConfirmed, setNavigationConfirmed] = useState(false);
  const { prevLocation } = useCustomNavigation();
  const navigate = useNavigate();

  const isDirtyRef = useRef(isFormDirty);

  useEffect(() => {
    isDirtyRef.current = isFormDirty;
  }, [isFormDirty]);

  const handlePopState = (e) => {
    if (isDirtyRef.current && !navigationConfirmed) {
      setShowExitModal(true);
      window.history.pushState(null, "", window.location.href);
    }
  };

  const handleBeforeUnload = (e) => {
    if (isFormDirty && !navigationConfirmed) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  useEffect(() => {
    if (isDirtyRef.current) {
      window.history.pushState(null, "", window.location.href);

      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  const handleConfirmNavigation = () => {
    setNavigationConfirmed(true);
    setShowExitModal(false);
    if (prevLocation?.pathname) {
      const { pathname } = prevLocation;
      navigate(pathname);
    } else {
      navigate(-1);
    }
  };

  const handleCancelNavigation = () => {
    setShowExitModal(false);
  };

  return (
    <Modal
      open={showExitModal}
      onClose={handleCancelNavigation}
      aria-labelledby="exit-modal-title"
      aria-describedby="exit-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, sm: 400 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Stack spacing={2} alignItems="center">
          <WarningAmberIcon color="warning" sx={{ fontSize: 48 }} />
          <Typography
            id="exit-modal-title"
            variant="h5"
            component="h2"
            gutterBottom
          >
            Μη αποθηκευμένες αλλαγές
          </Typography>
          <Typography
            id="exit-modal-description"
            variant="body1"
            color="text.secondary"
          >
            Έχετε αλλαγές που δεν έχουν αποθηκευτεί. Είστε σίγουροι ότι θέλετε
            να φύγετε;
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <Button
              onClick={handleConfirmNavigation}
              sx={{
                minWidth: 100,
                "&:hover": {
                  backgroundColor: colors.primary,
                  opacity: 0.8,
                },
              }}
            >
              Ναι
            </Button>
            <Button
              onClick={handleCancelNavigation}
              sx={{
                minWidth: 100,
                "&:hover": {
                  backgroundColor: colors.primary,
                  opacity: 0.8,
                },
              }}
            >
              Όχι
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

ExitFormModal.propTypes = {
  isFormDirty: PropTypes.bool.isRequired,
};

export default ExitFormModal;
