const contractResponseFields = [
  {
    label: "Τύπος",
    name: "type",
    type: "select",
    required: true,
    editable: false,
  },
  {
    label: "Τίτλος",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Aριθμός",
    name: "number",
    type: "text",
    required: true,
  },
  {
    label: "Περιγραφή",
    name: "description",
    type: "text",
    required: false,
  },
  {
    label: "Συμβαλλόμενη Εταιρεία",
    name: "externalCompany",
    type: "select",
    required: true,
  },
  {
    label: "Ημ/νία Έναρξης",
    name: "validFrom",
    type: "date",
    required: true,
  },
  {
    label: "Ημ/νία Λήξης",
    name: "validTo",
    type: "date",
    required: true,
  },
  {
    label: "Κατάσταση",
    name: "status",
    type: "select",
    required: true,
    editable: false,
    options: [
      {
        label: "Ενεργή",
        id: "Active",
      },
      {
        label: "Ανενεργή",
        id: "Inactive",
      },
    ],
  },
  {
    label: "Αρχεία",
    name: "files",
    type: "file",
    description: "Επισύναψη αρχείων",
    multiple: true,
    validTypes: ["application/pdf", "image/jpeg", "image/png"],
    maxSize: 5000000,
  },
];

export default contractResponseFields;
