import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

const baseQueryConfig = {
  staleTime: Infinity,
  enabled: false,
  retry: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const useGetBulkAssetTemplate = () => {
  return useQuery({
    queryKey: ["getBulkAssetTemplate"],
    queryFn: () => Agent.bulkImportAsset.getBulkAssetTemplate(),
    ...baseQueryConfig,
  });
};

export const useImportBulkAsset = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["importBulkAsset"],
    mutationFn: (body) => Agent.bulkImportAsset.importBulkAsset(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllAssets"]);
      queryClient.invalidateQueries(["getAssetCategoryCount"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
