import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import { useAlert } from "hooks/useAlert";
import SpinningCircle from "components/spinners/SpinningCircle";
import { convertUnderscoreToSpace } from "utils/stringConverter";

const MassEquipmentImportUploader = ({
  onFileUpload,
  requiredHeaders,
  selectedEquipmentTypeName,
  selectedAssetName,
}) => {
  const { showError, showSuccess } = useAlert();
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        if (
          file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          showError("Το αρχείο πρέπει να είναι τύπου .xlsx");
          return;
        }

        setIsUploading(true);
        setFileName(file.name);

        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const headers = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            range: "A1:H1",
          })[0];

          const missingHeaders = requiredHeaders?.filter(
            (header) => !headers?.includes(header)
          );

          if (missingHeaders?.length > 0) {
            showError(
              "Οι στήλες του excel που ανεβάσατε δεν μοιάζουν με αυτές του προτύπου"
            );
            setIsUploading(false);
            setFileName("");
            return;
          }

          const rowData = XLSX.utils.sheet_to_json(worksheet, {
            range: "A1:H1000",
          });

          const parsedData = rowData.map((row) => {
            requiredHeaders.forEach((header) => {
              if (!row[header]) {
                row[header] = "";
              }
              if (
                header === "Τύπος" ||
                header === "Ομάδα" ||
                header === "Κλάση"
              ) {
                row[header] = convertUnderscoreToSpace(row[header]);
              }
            });

            return row;
          });

          const mismatchedTypeRows = parsedData?.filter(
            (row) => row["Τύπος"] !== selectedEquipmentTypeName
          );
          const mismatchedAssetRows = parsedData?.filter(
            (row) => row["Ακίνητο"] !== selectedAssetName
          );

          if (mismatchedTypeRows.length > 0) {
            showError("Ο τύπος στο excel δεν ταιριάζει με τον επιλεγμένο τύπο");
            setIsUploading(false);
            setFileName("");
            return;
          }

          if (mismatchedAssetRows.length > 0) {
            showError(
              "Το ακίνητο στο excel δεν ταιριάζει με το ακίνητο που επιλέξατε"
            );
            setIsUploading(false);
            setFileName("");
            return;
          }

          onFileUpload(parsedData);
          setIsUploading(false);
          showSuccess("Το αρχείο ανέβηκε με επιτυχία");
        };
        reader.readAsArrayBuffer(file);
      }
    },
    [
      onFileUpload,
      requiredHeaders,
      selectedEquipmentTypeName,
      selectedAssetName,
      showError,
      showSuccess,
    ]
  );

  const handleClearFile = () => {
    setFileName("");
    setIsUploading(false);
    onFileUpload(null);
    showSuccess("Το αρχείο αφαιρέθηκε με επιτυχία");
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".xlsx",
    onDrop,
    multiple: false,
    disabled: !!fileName,
  });

  return (
    <Box
      {...(!fileName ? getRootProps() : {})}
      sx={{
        border: "2px dashed #ddd",
        padding: 2,
        borderRadius: 2,
        textAlign: "center",
        position: "relative",
        cursor: fileName ? "default" : "pointer",
        backgroundColor: fileName ? "#f0f8ff" : "#fff",
        transition: "background-color 0.3s",
      }}
    >
      <input {...getInputProps()} />
      <Typography variant="body1">
        {fileName
          ? `Αρχείο: ${fileName}`
          : "Σύρετε το αρχείο σας εδώ ή κάντε κλικ για να επιλέξετε αρχείο"}
      </Typography>
      {isUploading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <SpinningCircle />
        </Box>
      )}
      {fileName && !isUploading && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <CustomButton
            title="Διαγραφή"
            onClick={handleClearFile}
            orientation="row"
            fontSize={14}
            fontWeight={600}
            sx={{
              minWidth: 120,
              maxHeight: 20,
              paddingY: 3,
              borderRadius: 3,
            }}
          />
        </Box>
      )}

      {!isUploading && !fileName && (
        <CustomButton
          title="Επιλογή Αρχείου"
          orientation="row"
          fontSize={14}
          fontWeight={600}
          sx={{
            marginTop: 2,
            minWidth: 120,
            maxHeight: 20,
            paddingY: 3,
            borderRadius: 3,
          }}
        />
      )}
    </Box>
  );
};

MassEquipmentImportUploader.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  requiredHeaders: PropTypes.arrayOf(PropTypes.string),
  selectedEquipmentTypeName: PropTypes.string,
  selectedAssetName: PropTypes.string,
};

export default MassEquipmentImportUploader;
