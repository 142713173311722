import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import CustomButton from "components/buttons/CustomButton";

dayjs.extend(quarterOfYear);

const DatePeriodSelector = ({ onSelectPeriod }) => {
  const periods = [
    {
      label: "Επόμενος Μήνας",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").add(1, "month").toDate(),
        dateTo: dayjs().endOf("month").add(1, "month").toDate(),
      }),
    },
    {
      label: "Επόμενο Τρίμηνο",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("quarter").add(1, "month").toDate(),
        dateTo: dayjs().endOf("quarter").add(1, "month").toDate(),
      }),
    },
    {
      label: "Επόμενο Εξάμηνο",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").add(1, "month").toDate(),
        dateTo: dayjs()
          .startOf("month")
          .add(6, "month")
          .endOf("month")
          .toDate(),
      }),
    },
  ];

  return (
    <Box display="flex" gap={2} alignItems="center">
      <Typography variant="h6">Επιλέξτε Περίοδο</Typography>
      {periods.map((period) => (
        <CustomButton
          key={period.label}
          title={period.label}
          onClick={() => {
            const { dateFrom, dateTo } = period.calculateDates();
            onSelectPeriod(dateFrom, dateTo);
          }}
          sx={{
            padding: "8px 16px",
          }}
          fontSize={13}
          fontWeight={400}
        >
          {period.label}
        </CustomButton>
      ))}
    </Box>
  );
};

DatePeriodSelector.propTypes = {
  onSelectPeriod: PropTypes.func.isRequired,
};

export default DatePeriodSelector;
