import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);
  const currentLocationRef = useRef(location);

  useEffect(() => {
    if (currentLocationRef.current.pathname !== location.pathname) {
      setPrevLocation(currentLocationRef.current);
      currentLocationRef.current = location;
    }
  }, [location]);

  const value = useMemo(() => ({ prevLocation }), [prevLocation]);

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCustomNavigation = () => {
  return useContext(NavigationContext);
};
