import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import CancelButton from "components/buttons/CancelButton";
import { useNavigate } from "react-router";
import enums from "config/enums";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import contractRequestFields from "config/forms/contractRequestFields";
import SpinningCircle from "components/spinners/SpinningCircle";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import ExitFormModal from "components/modals/ExitFormModal";

const ContractCreateForm = ({ onCreate }) => {
  const navigate = useNavigate();
  const isCreatingLoading =
    useIsMutating({ mutationKey: ["createContract"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;

  const schema = yup.object(
    contractRequestFields.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "status") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const defaultValues = contractRequestFields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue;
    return acc;
  }, {});

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const type = watch("type");

  const selectedContractType = useMemo(() => {
    return enums.ContractTypes.find((t) => t.label === type)?.id;
  }, [type]);

  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: errorCompanies,
  } = useGetAllCompanies(
    {
      pageNumber: -1,
      pageSize: -1,
      serviceType: [selectedContractType],
    },
    { enabled: !!selectedContractType }
  );

  if (isCompaniesLoading) {
    return <SpinningCircle />;
  }

  if (errorCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorCompanies.message}</p>
      </Box>
    );
  }

  const getInputElementByFieldType = (field) => {
    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return <CustomTextField {...field} control={control} />;
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "type") {
        availableOptions = enums.ContractTypes.map((option) => option.label);
      }

      if (field.name === "externalCompany") {
        availableOptions = companies?.items.map((company) => company.name);
      }

      return (
        <DropdownField
          {...field}
          control={control}
          options={availableOptions}
          boolean={field.type === "boolean"}
          onChange={(e) => {
            if (field.name === "type") {
              setValue("externalCompany", null);
            }
          }}
        />
      );
    } else if (field.type === "date") {
      return <DateField {...field} control={control} />;
    } else if (field.type === "file") {
      return <AlfrescoFileUploader {...field} control={control} />;
    }
  };

  const formFields = contractRequestFields;

  return (
    <>
      <form
        onSubmit={handleSubmit(() =>
          onCreate({
            ...getValues(),
            externalCompanyId: companies?.items.find(
              (company) => company.name === getValues("externalCompany")
            )?.id,
          })
        )}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Grid container spacing={2}>
            {formFields
              .filter((field) => field.display !== false)
              .map((field) => {
                if (field.name === "type") {
                  return (
                    <Grid key={field?.name} container item>
                      <Grid item xs={6}>
                        {getInputElementByFieldType(field)}
                      </Grid>
                    </Grid>
                  );
                } else if (selectedContractType) {
                  if (field.type === "file") {
                    return (
                      <Grid item xs={12} key={field?.name}>
                        <Box width={{ xs: "100%", lg: "50%" }}>
                          {getInputElementByFieldType(field)}
                        </Box>
                      </Grid>
                    );
                  } else
                    return (
                      <Grid item xs={12} md={4} key={field?.name}>
                        {getInputElementByFieldType(field)}
                      </Grid>
                    );
                }
                return null;
              })}
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλούμε ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                onClick={() => {
                  reset();
                  navigate(-1);
                }}
              />
            </Box>
            <SaveButton text="Δημιουργία" isLoading={isCreatingLoading} />
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

ContractCreateForm.propTypes = {
  onCreate: PropTypes.func,
};

export default ContractCreateForm;
