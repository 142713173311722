import PropTypes from "prop-types";
import { Box, Modal, Tooltip } from "@mui/material";
import colors from "config/theme/colors";
import MassImportStepper from "../steppers/MassImportStepper";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const MassImportStepperModal = ({
  isOpen = false,
  handleClose = () => {},
  handleSubmit = () => {},
  steps = [],
  disabledSubmitButton = false,
  disabledNextButton = false,
  setFileData,
  filteredFileData = [],
  warningTitle,
}) => {
  const onSubmit = () => {
    handleSubmit();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    handleClose();
  };

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "4px",
          minWidth: 600,
          maxHeight: "90vh",
          minHeight: 100,
          padding: 2,
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box display="flex" justifyContent="end" borderRadius={"4px 4px 0 0"}>
          <Tooltip onClick={handleCancel} style={{ cursor: "pointer" }}>
            <CloseIcon stroke={colors.primary} />
          </Tooltip>
        </Box>

        <Box
          sx={{
            mt: 4,
          }}
        >
          <MassImportStepper
            steps={steps}
            handleSubmit={onSubmit}
            disabledSubmitButton={disabledSubmitButton}
            disabledNextButton={disabledNextButton}
            setFileData={setFileData}
            filteredFileData={filteredFileData}
            warningTitle={warningTitle}
          />
        </Box>
      </Box>
    </Modal>
  );
};

MassImportStepperModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  steps: PropTypes.array,
  handleSubmit: PropTypes.func,
  disabledSubmitButton: PropTypes.bool,
  disabledNextButton: PropTypes.bool,
  setFileData: PropTypes.func,
  filteredFileData: PropTypes.array,
  warningTitle: PropTypes.string,
};

export default MassImportStepperModal;
