import PropTypes from "prop-types";
import { Box, Modal, Tooltip, Typography } from "@mui/material";
import colors from "config/theme/colors";
import BasicStepper from "../../../components/steppers/BasicStepper";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const EquipmentMaintenancesMassImportStepperModal = ({
  isOpen = false,
  handleClose = () => {},
  steps = [],
  setSelectedEquipments,
  setSelectedEquipmentType,
  setSelectedCompany,
  setSelectedContract,
}) => {
  const reset = () => {
    setSelectedEquipments([]);
    setSelectedEquipmentType(null);
    setSelectedCompany(null);
    setSelectedContract(null);
    handleClose();
  };

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    reset();
  };

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mx: 2,
      }}
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "4px",
          maxHeight: "90vh",
          minHeight: 100,
          overflowY: "auto",
          padding: 2,
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box></Box>
          <Typography color={colors.primary} fontSize={20}>
            Μαζική Εισαγωγή Συντηρήσεων
          </Typography>
          <Box
            borderRadius={16}
            sx={{
              position: "relative",
              right: 0,
              top: 0,
              padding: 0.5,
              backgroundColor: colors.white,
              "&:hover": {
                backgroundColor: colors.lightGrey,
                borderRadius: 16,
              },
            }}
          >
            <Tooltip onClick={reset} style={{ cursor: "pointer" }}>
              <CloseIcon stroke={colors.primary} />
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 4,
          }}
        >
          <BasicStepper steps={steps} />
        </Box>
      </Box>
    </Modal>
  );
};

EquipmentMaintenancesMassImportStepperModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  steps: PropTypes.array,
  setSelectedEquipments: PropTypes.func,
  setSelectedEquipmentType: PropTypes.func,
  setSelectedCompany: PropTypes.func,
  setSelectedContract: PropTypes.func,
};

export default EquipmentMaintenancesMassImportStepperModal;
