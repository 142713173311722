import { Box } from "@mui/material";
import SubHeader from "components/headers/SubHeader";
import MainPageLayout from "components/main/MainPageLayout";
import { useState } from "react";
import AssetCreateForm from "./AssetCreateForm";
import {
  useGetAllAssetCategories,
  useGetAssetControlsByCategoryId,
} from "hooks/queries/useAssetCategories";
import DropdownField from "components/inputs/DropdownField";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useAlert } from "hooks/useAlert";
import { useCreateAsset } from "hooks/queries/useAssets";
import { useNavigate } from "react-router";
import transformDotFieldsToObject from "utils/transformDotFieldsToJSON";
import enums from "config/enums";
import clearDotAnnotatedFields from "utils/clearDotAnnotatedFields";
import { useUploadFiles } from "hooks/queries/useFile";
import CustomButton from "components/buttons/CustomButton";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

const ASSET_DETAILS_TABS = [
  {
    id: -1,
    description: "Γενικά",
  },
];

const AssetCreate = () => {
  const { showError, showSuccess, showWarning } = useAlert();
  const [selectedTab, setSelectedTab] = useState(-1);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState("");
  const { data: assetCategories, isLoading: assetCategoriesLoading } =
    useGetAllAssetCategories();
  const { data: availableTabs } = useGetAssetControlsByCategoryId(
    selectedAssetCategory?.id
  );
  const { mutate: uploadFiles } = useUploadFiles({
    fileableType: "Asset",
    config: {
      onError: (error) => {
        showWarning(
          "Το ακίνητο δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
        );
        navigate("/assets");
        console.error(error);
      },
      onSuccess: () => {
        showSuccess("Το ακίνητο δημιουργήθηκε με επιτυχία");
        navigate("/assets");
      },
    },
  });
  const { mutate: createAsset } = useCreateAsset({
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Το ακίνητο δημιουργήθηκε με επιτυχία");
        navigate("/assets");
      },
    },
  });
  const navigate = useNavigate();

  if (assetCategoriesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  const availableCategoriesOptions = assetCategories?.items?.map(
    (item) => item.description
  );

  const handleCreateClick = (data) => {
    const formData = transformDotFieldsToObject(data);
    const { geoLocation, complianceAudit, technicalControl, ...restFormData } =
      formData;

    const payload = {
      ...restFormData,
      assetCategoryId: selectedAssetCategory?.id,
      lat: geoLocation?.lat,
      long: geoLocation?.lng,
      address: geoLocation?.formattedAddress,
      postalCode: geoLocation?.postalCode,
      city: geoLocation?.city,
      complianceAudit: {
        ...complianceAudit,
        ownership: enums.Ownership.find(
          (item) => item.label === complianceAudit?.ownership
        )?.id,
        ownershipType: enums.OwnershipType.find(
          (item) => item.label === complianceAudit?.ownershipType
        )?.id,
        notes: enums.LegalControlNotesOptions.find(
          (item) => item.label === complianceAudit?.notes
        )?.id,
      },
      technicalControl: {
        ...technicalControl,
        cityPlanningCharacter: enums.CityPlanningCharacter.find(
          (item) => item.label === technicalControl?.cityPlanningCharacter
        )?.id,
      },
    };

    const files = [
      ...(data.generalFiles || []),
      ...(data.complianceFiles || []),
      ...(data.financialFiles || []),
    ];

    createAsset(clearDotAnnotatedFields(payload), {
      onSuccess: (asset) => {
        if (files?.length > 0) {
          uploadFiles({ files, entityId: asset?.result?.id });
        } else {
          showSuccess("Το ακίνητο δημιουργήθηκε με επιτυχία");
          navigate("/assets");
        }
      },
    });
  };

  return (
    <MainPageLayout
      pageTitle={"Νέο Ακίνητο"}
      pageSubTitle={"Προσθήκη νέου ακινήτου"}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "flex-end",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <CustomButton
          icon={<UploadFileOutlinedIcon />}
          title="Μαζική Προσθήκη"
          orientation="row"
          fontSize={13}
          fontWeight={700}
          sx={{
            minHeight: "33px",
            maxHeight: "33px",
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("mass-import");
          }}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          width: "100%",
        }}
        mb={2}
      >
        <DropdownField
          label="Επιλογή Κατηγορίας Ακινήτου"
          value={selectedAssetCategory}
          onChange={(_, value) =>
            setSelectedAssetCategory(
              assetCategories?.items?.find((item) => item.description === value)
            )
          }
          options={availableCategoriesOptions}
          required
        />
      </Box>

      {selectedAssetCategory && availableTabs && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            flexWrap={"wrap"}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
          >
            <SubHeader
              tabs={[...ASSET_DETAILS_TABS, ...availableTabs]}
              setSelectedTab={setSelectedTab}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <AssetCreateForm
              selectedTab={selectedTab}
              onCreate={handleCreateClick}
              selectedAssetCategory={selectedAssetCategory}
            />
          </Box>
        </Box>
      )}
    </MainPageLayout>
  );
};

export default AssetCreate;
