import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import AuthenticatedRoutes from "routes/AuthenticatedRoutes";
import AuthenticatedContent from "./AuthenticatedContent";
import GuestRoutes from "routes/GuestRoutes";
import GuestContent from "./GuestContent";
import { useAuthStore } from "stores/AuthStore";
import TokenExpirationChecker from "pages/auth/TokenExpirationChecker";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { NavigationProvider } from "components/providers/NavigationProvider";

const AppContent = () => {
  const queryClient = useQueryClient();
  const authStore = useAuthStore();

  useEffect(() => {
    if (!authStore.isUserAuthenticated) {
      queryClient.clear();
    }
  }, [authStore.isUserAuthenticated]);

  return (
    <BrowserRouter>
      <NavigationProvider>
        {authStore.isUserAuthenticated ? (
          <AuthenticatedContent>
            <AuthenticatedRoutes />
            <TokenExpirationChecker />
          </AuthenticatedContent>
        ) : (
          <GuestContent>
            <GuestRoutes />
          </GuestContent>
        )}
      </NavigationProvider>
    </BrowserRouter>
  );
};

export default observer(AppContent);
