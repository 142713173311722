import colors from "config/theme/colors";

export const getTaskColor = (status) => {
  switch (status) {
    case "Νέο":
      return colors.taskNew;
    case "Ληξιπρόθεσμο":
      return colors.taskOverdue;
    case "Εκκρεμεί":
      return colors.taskPending;
    case "Ολοκληρωμένο":
      return colors.success;
    default:
      return colors.primary;
  }
};
