import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import DeleteButton from "components/buttons/DeleteButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/inputs/CustomTextField";
import colors from "config/theme/colors";
import DropdownField from "components/inputs/DropdownField";
import { useGetAllAssetCategories } from "hooks/queries/useAssetCategories";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import DeleteModal from "components/modals/DeleteModal";
import ExitFormModal from "components/modals/ExitFormModal";

const types = [
  { id: "Asset", label: "Ακίνητο" },
  { id: "Equipment", label: "Εξοπλισμός" },
];

const MaintenanceTypeForm = ({
  isCreateForm = false,
  selectedMaintenanceType,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: assetCategories,
    isLoading: assetCategoriesLoading,
    error: assetCategoriesError,
  } = useGetAllAssetCategories(null, null, {
    select: (data) => data?.result?.items,
  });
  const {
    data: equipmentCategories,
    isLoading: equipmentCategoriesLoading,
    error: equipmentCategoriesError,
  } = useGetAllEquipmentTypes(
    {},
    {
      select: (data) => data?.result?.items,
    }
  );

  const isCreatingLoading =
    useIsMutating({
      mutationKey: ["createMaintenanceType"],
    }) > 0;
  const isUpdateLoading =
    useIsMutating({
      mutationKey: ["updateMaintenanceType"],
    }) > 0;
  const isDeleteLoading =
    useIsMutating({
      mutationKey: ["deleteMaintenanceType"],
    }) > 0;

  const schema = yup.object({
    name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    description: yup.string().nullable(),
    type: yup
      .object()
      .shape({
        id: yup.string(),
        label: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      })
      .required("Το πεδίο είναι υποχρεωτικό"),
    typeCategoriesIds: yup
      .array()
      .of(yup.object().shape({ id: yup.number(), label: yup.string() }))
      .min(1, "Το πεδίο είναι υποχρεωτικό"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchedType = watch("type");

  useEffect(() => {
    const defaultValues = selectedMaintenanceType
      ? {
          name: selectedMaintenanceType.name || "",
          description: selectedMaintenanceType.description || "",
          type: types.find((type) => type.id === selectedMaintenanceType.type),
          typeCategoriesIds:
            selectedMaintenanceType.type === "Asset"
              ? selectedMaintenanceType?.assetCategories
              : selectedMaintenanceType?.equipmentTypes,
        }
      : {
          name: "",
          description: "",
          type: {
            id: "",
            label: "",
          },
          typeCategoriesIds: [],
        };
    reset(defaultValues);
  }, [selectedMaintenanceType, reset]);

  if (assetCategoriesLoading || equipmentCategoriesLoading) {
    return <SpinningCircle />;
  }

  if (assetCategoriesError || equipmentCategoriesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {assetCategoriesError.message}</p>
      </Box>
    );
  }

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const disabled = !isEditing && !isCreateForm;

  return (
    <>
      <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography fontSize={24} fontWeight={600} color={colors.secondary}>
            {isCreateForm
              ? "Δημιουργία Τύπου"
              : `${selectedMaintenanceType?.name} - ${isEditing ? "Επεξεργασία" : "Προβολή"}`}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                name="name"
                label="Όνομα"
                type="text"
                control={control}
                disabled={disabled}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                name="description"
                label="Περιγραφή"
                type="textarea"
                control={control}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownField
                name="type"
                label="Τύπος"
                control={control}
                disabled={disabled}
                editable={isCreateForm}
                required
                onChange={(e) => {
                  setValue("typeCategoriesIds", null);
                }}
                options={types}
              />
            </Grid>

            {watchedType &&
              watchedType.id !== "" &&
              watchedType.label !== "" && (
                <Grid item xs={12}>
                  <DropdownField
                    name="typeCategoriesIds"
                    label="Τύπος Κατηγορίας"
                    control={control}
                    disabled={disabled}
                    editable={false || isCreateForm}
                    required
                    multiple
                    options={
                      watchedType === "Asset" || watchedType.id === "Asset"
                        ? assetCategories?.map((category) => ({
                            id: category.id,
                            label: category.description,
                          }))
                        : equipmentCategories?.map((category) => ({
                            id: category.id,
                            label: category.name,
                          }))
                    }
                  />
                </Grid>
              )}
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              {isCreateForm && isDirty && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                  disabled={disabled}
                />
              )}
              {!isCreateForm && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                />
              )}
              {!isCreateForm && isEditing && (
                <DeleteButton
                  onClick={handleOpenModal}
                  text="Διαγραφή Τύπου"
                  disabled={disabled}
                  isLoading={isDeleteLoading}
                />
              )}
            </Box>

            {(isEditing || isCreateForm) && (
              <SaveButton
                disabled={disabled || !isDirty}
                text={isCreateForm ? "Δημιουργία" : "Αποθήκευση"}
                isLoading={isUpdateLoading || isCreatingLoading}
              />
            )}

            {isEditing && (
              <DeleteModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onDelete={onDelete}
                itemTitle={selectedMaintenanceType?.name}
                title="Πρόκειται να διαγράψετε τον τύπο"
                description="Σιγουρευτείτε ότι ο συγκεκριμένος τύπος δεν συσχετίζεται με κάποια συντήρηση. Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του τύπου;"
              />
            )}
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

MaintenanceTypeForm.propTypes = {
  isCreateForm: PropTypes.bool,
  selectedMaintenanceType: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default MaintenanceTypeForm;
