import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SpinningCircle from "components/spinners/SpinningCircle";
import DropdownField from "components/inputs/DropdownField";
import PropTypes from "prop-types";
import colors from "config/theme/colors";
import { useGetAllEquipment } from "hooks/queries/useEquipment";
import { useOutletContext } from "react-router";

const SelectEquipmentType = ({
  equipmentTypes,
  selectedEquipmentType,
  setSelectedEquipmentType,
  selectedEquipment,
  setSelectedEquipment,
}) => {
  const { selectedAsset } = useOutletContext();
  const availableTypesOptions = equipmentTypes?.map((type) => ({
    id: type.id,
    label: type.name,
  }));

  const {
    data: equipments,
    isLoading: equipmentsLoading,
    isError: equipmentsError,
  } = useGetAllEquipment(
    {
      equipmentTypeIds: [selectedEquipmentType?.id],
      assetId: selectedAsset?.id,
    },
    {
      enabled: !!selectedEquipmentType,
      select: (data) => data?.result?.items,
    }
  );

  return (
    <Box mt={2}>
      <Typography color={colors.primary} fontWeight={700} fontSize={21} mb={2}>
        Επιλέξτε τον τύπο του εξοπλισμού
      </Typography>

      <DropdownField
        label="Επιλογή Τύπου Εξοπλισμού"
        options={availableTypesOptions}
        value={selectedEquipmentType}
        onChange={(_, value) => setSelectedEquipmentType(value)}
      />

      <Box mt={3}>
        {selectedEquipmentType && (
          <>
            <Typography
              color={colors.primary}
              fontWeight={700}
              fontSize={21}
              mb={2}
            >
              Επιλέξτε τον επιθυμητό εξοπλισμό
            </Typography>

            {equipmentsLoading && <SpinningCircle />}

            {equipmentsError && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%",
                }}
              >
                <p>Error: {equipmentsError?.message}</p>
              </Box>
            )}

            {equipments && (
              <DropdownField
                label="Επιλογή Εξοπλισμού"
                options={equipments?.map((equipment) => ({
                  id: equipment.id,
                  label: equipment.name,
                }))}
                value={selectedEquipment}
                onChange={(_, value) => setSelectedEquipment(value)}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

SelectEquipmentType.propTypes = {
  equipmentTypes: PropTypes.array,
  selectedEquipmentType: PropTypes.object,
  setSelectedEquipmentType: PropTypes.func,
  selectedEquipment: PropTypes.object,
  setSelectedEquipment: PropTypes.func,
};

export default SelectEquipmentType;
