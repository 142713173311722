import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import SaveButton from "components/buttons/SaveButton";
import userRequestFields from "config/forms/userRequestFields";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import CancelButton from "components/buttons/CancelButton";
import { useNavigate } from "react-router";
import { useGetAllRoles } from "hooks/queries/useAuthSetup";
import ExitFormModal from "components/modals/ExitFormModal";

const UserCreateForm = ({ onCreate }) => {
  const { data: availableRoles } = useGetAllRoles({
    select: (data) => data?.result,
  });
  const isCreatingLoading = useIsMutating({ mutationKey: ["createUser"] }) > 0;
  const navigate = useNavigate();

  const schema = yup.object(
    userRequestFields.reduce((acc, field) => {
      let fieldSchema;

      if (
        field.type === "text" ||
        field.type === "textarea" ||
        field.type === "file"
      ) {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const defaultValues = userRequestFields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue;
    return acc;
  }, {});

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getInputElementByFieldType = (field) => {
    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea" ||
      field.type === "file"
    ) {
      return <CustomTextField {...field} control={control} />;
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "role") {
        availableOptions = availableRoles?.map((option) => option.name);
      }
      return (
        <DropdownField
          {...field}
          control={control}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return <DateField {...field} control={control} />;
    }
  };

  const formFields = userRequestFields;

  return (
    <>
      <form onSubmit={handleSubmit(() => onCreate(getValues()))}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Grid container spacing={2}>
            {formFields
              .filter((field) => field.display !== false)
              .map((field) => (
                <Grid item xs={12} md={4} key={field?.name}>
                  {getInputElementByFieldType(field)}
                </Grid>
              ))}
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλούμε ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                onClick={() => {
                  reset();
                  navigate(-1);
                }}
              />
            </Box>
            <SaveButton text="Δημιουργία" isLoading={isCreatingLoading} />
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

UserCreateForm.propTypes = {
  onCreate: PropTypes.func,
};

export default UserCreateForm;
