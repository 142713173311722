import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import DeleteButton from "components/buttons/DeleteButton";
import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/inputs/CustomTextField";
import colors from "config/theme/colors";
import DropdownField from "components/inputs/DropdownField";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import DeleteModal from "components/modals/DeleteModal";
import { useGetAllCertificationClasses } from "hooks/queries/useCertificationClass";
import ExitFormModal from "components/modals/ExitFormModal";

const types = [
  { id: "Asset", label: "Ακίνητο" },
  { id: "Equipment", label: "Εξοπλισμός" },
];

const CertificationTypeForm = ({
  isCreateForm = false,
  selectedCertificationType,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: certificationClassOptions,
    isLoading: certificationClassesLoading,
    error: certificationClassesError,
  } = useGetAllCertificationClasses(
    {
      pageNumber: null,
      pageSize: null,
    },
    {
      select: (data) => data?.result?.items,
    }
  );

  const isCreatingLoading =
    useIsMutating({
      mutationKey: ["createCertificationType"],
    }) > 0;
  const isUpdateLoading =
    useIsMutating({
      mutationKey: ["updateCertificationType"],
    }) > 0;
  const isDeleteLoading =
    useIsMutating({
      mutationKey: ["deleteCertificationType"],
    }) > 0;

  const schema = yup.object({
    name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    description: yup.string().nullable(),
    certificationClassId: yup
      .object()
      .shape({
        id: yup.string(),
        label: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      })
      .required("Το πεδίο είναι υποχρεωτικό"),
    type: yup
      .object()
      .shape({
        id: yup.string(),
        label: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      })
      .required("Το πεδίο είναι υποχρεωτικό"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const defaultValues = selectedCertificationType
      ? {
          name: selectedCertificationType.name || "",
          description: selectedCertificationType.description || "",
          type: types.find(
            (type) => type.id === selectedCertificationType.type
          ),
          certificationClassId: {
            id: certificationClassOptions?.find(
              (classItem) =>
                classItem.id === selectedCertificationType.certificationClassId
            ).id,
            label: certificationClassOptions?.find(
              (classItem) =>
                classItem.id === selectedCertificationType.certificationClassId
            ).name,
          },
        }
      : {
          name: "",
          description: "",
          certificationClassId: null,
        };
    reset(defaultValues);
  }, [selectedCertificationType, reset, certificationClassOptions]);

  if (certificationClassesLoading) {
    return <SpinningCircle />;
  }

  if (certificationClassesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {certificationClassesError.message}</p>
      </Box>
    );
  }

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const disabled = !isEditing && !isCreateForm;

  return (
    <>
      <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography fontSize={24} fontWeight={600} color={colors.secondary}>
            {isCreateForm
              ? "Δημιουργία Τύπου"
              : `${selectedCertificationType?.name} - ${isEditing ? "Επεξεργασία" : "Προβολή"}`}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                name="name"
                label="Όνομα"
                type="text"
                control={control}
                disabled={disabled}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                name="description"
                label="Περιγραφή"
                type="textarea"
                control={control}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownField
                name="type"
                label="Τύπος"
                control={control}
                disabled={disabled}
                editable={isCreateForm}
                required
                onChange={() => {
                  setValue("typeCategoriesIds", null);
                }}
                options={types}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownField
                name="certificationClassId"
                label="Κλάση Πιστοποίησης"
                control={control}
                disabled={disabled}
                editable={isCreateForm}
                required
                options={certificationClassOptions?.map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
              />
            </Grid>
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              {isCreateForm && isDirty && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                  disabled={disabled}
                />
              )}
              {!isCreateForm && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                />
              )}
              {!isCreateForm && isEditing && (
                <DeleteButton
                  onClick={handleOpenModal}
                  text="Διαγραφή Τύπου"
                  disabled={disabled}
                  isLoading={isDeleteLoading}
                />
              )}
            </Box>

            {(isEditing || isCreateForm) && (
              <SaveButton
                disabled={disabled || !isDirty}
                text={isCreateForm ? "Δημιουργία" : "Αποθήκευση"}
                isLoading={isUpdateLoading || isCreatingLoading}
              />
            )}

            {isEditing && (
              <DeleteModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onDelete={onDelete}
                itemTitle={selectedCertificationType?.name}
                title="Πρόκειται να διαγράψετε τον τύπο"
                description="Σιγουρευτείτε ότι ο συγκεκριμένος τύπος δεν συσχετίζεται με κάποια πιστοποίησης. Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του τύπου;"
              />
            )}
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

CertificationTypeForm.propTypes = {
  isCreateForm: PropTypes.bool,
  selectedCertificationType: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CertificationTypeForm;
