import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllTasks = (
  {
    pageNumber = -1,
    pageSize = -1,
    searchParameter = "",
    maintenanceId = null,
    rentId = null,
    scheduledTaskStatus = [],
    orderBy = "",
    sortingOrder = "",
  } = {
    pageNumber: -1,
    pageSize: -1,
    searchParameter: "",
    maintenanceId: null,
    rentId: null,
    scheduledTaskStatus: [],
    orderBy: "",
    sortingOrder: "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllTasks",
      pageNumber,
      pageSize,
      searchParameter,
      maintenanceId,
      rentId,
      scheduledTaskStatus,
      orderBy,
      sortingOrder,
    ],
    queryFn: () =>
      Agent.tasks.getAllTasks({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchParameter: searchParameter,
        maintenanceId: maintenanceId,
        rentId: rentId,
        scheduledTaskStatus: scheduledTaskStatus,
        orderBy: orderBy,
        sortingOrder: sortingOrder,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetTaskById = (taskId, options = {}) => {
  return useQuery({
    queryKey: ["getTaskById", +taskId],
    queryFn: () => Agent.tasks.getTaskById(taskId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetTaskCount = (options = {}) => {
  return useQuery({
    queryKey: ["getTaskCount"],
    queryFn: () => Agent.tasks.getTaskCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useUpdateTask = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateTask"],
    mutationFn: (body) => Agent.tasks.updateTask(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useForceCompleteTask = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["forceCompleteTask"],
    mutationFn: () => Agent.tasks.forceCompleteTask(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteTask = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteTask"],
    mutationFn: () => Agent.tasks.deleteTask(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
