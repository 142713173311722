import MaintenanceCreateForm from "pages/maintenances/Create/MaintenanceCreateForm";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useUserProfile } from "hooks/queries/useAuth";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import { useUploadFiles } from "hooks/queries/useFile";
import { useAlert } from "hooks/useAlert";
import { useCreateMaintenance } from "hooks/queries/useMaintenances";
import SpinningCircle from "components/spinners/SpinningCircle";
import { Box } from "@mui/material";

const CreateEquipmentMaintenance = forwardRef(
  ({ selectedEquipment, selectedEquipmentType }, ref) => {
    const { showWarning, showError } = useAlert();
    const {
      data: userProfile,
      isLoading: isProfileLoading,
      error: profileError,
    } = useUserProfile();
    const {
      data: maintenancesTypes,
      isLoading: isMaintenancesTypesLoading,
      error: errorMaintenancesTypes,
    } = useGetAllMaintenanceTypes({
      equipmentTypeIds: [selectedEquipmentType?.id],
    });
    const { mutateAsync: uploadFiles } = useUploadFiles({
      fileableType: "Maintenance",
      config: {
        onError: (error) => {
          showWarning(
            "Η συντήρηση δημιουργήθηκε, αλλά προέκυψε σφάλμα κατά την αποθήκευση των αρχείων. Δοκιμάστε ξανά."
          );
          console.error(error);
        },
      },
    });
    const { mutateAsync: createMaintenance } = useCreateMaintenance({
      config: {
        onError: (error) => {
          showError(error.message);
        },
      },
    });

    if (isMaintenancesTypesLoading || isProfileLoading) {
      return <SpinningCircle />;
    }

    if (errorMaintenancesTypes || profileError) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <p>
            Error:
            {errorMaintenancesTypes.message || profileError.message}
          </p>
        </Box>
      );
    }

    const handleCreateClick = async (data) => {
      const payload = {
        name: data.name || "",
        description: data.description || "",
        frequency: parseInt(data.frequency) || "",
        notes: data.notes || "",
        userId: userProfile?.userDto?.id || "",
        maintenanceTypeId:
          maintenancesTypes?.items?.find(
            (type) => type.name === data.maintenanceTypeId
          )?.id || null,
        contractId: data.contractId?.id || null,
        equipmentId: selectedEquipment.id,
        status: data.status?.id || "",
      };

      const files = data.files;

      try {
        const maintenance = await createMaintenance(payload);
        if (files?.length > 0) {
          await uploadFiles({ files, entityId: maintenance?.result?.id });
        }
      } catch (error) {
        showError(error.message);
        throw error;
      }
    };

    return (
      <MaintenanceCreateForm
        ref={ref}
        selectedEquipment={selectedEquipment}
        hideDefaultActionButtons
        onCreate={handleCreateClick}
        preselectedEquipmentType={selectedEquipmentType}
      />
    );
  }
);

CreateEquipmentMaintenance.propTypes = {
  selectedEquipment: PropTypes.array,
  selectedEquipmentType: PropTypes.string,
};

export default CreateEquipmentMaintenance;
