import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FilePreviewModal = ({ open, file, onClose }) => {
  const isImage = file?.type?.includes("image");
  const isPdf = file?.type?.includes("pdf");

  return (
    file && (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {file.name}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            {isImage && (
              <img
                src={file.preview}
                alt={file.name}
                style={{ maxWidth: "100%", maxHeight: "80vh" }}
              />
            )}

            {isPdf && (
              <iframe
                src={file.preview}
                style={{ width: "100%", height: "80vh", border: "none" }}
                title={file.name}
              />
            )}

            {!isImage && !isPdf && (
              <Box p={2}>
                <Typography variant="body2">
                  Το αρχείο δεν υποστηρίζεται για προεπισκόπηση.
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
};

FilePreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
    preview: PropTypes.any,
    type: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

export default FilePreviewModal;
