import dayjs from "dayjs";

export function formatDateTime(dateTimeString, dateOnly) {
  const date = dayjs(dateTimeString).utcOffset(0);
  if (date.isValid()) {
    return dateOnly
      ? date.format("DD/MM/YYYY")
      : date.format("DD/MM/YYYY HH:mm");
  } else {
    return "--/--/----";
  }
}
