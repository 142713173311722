import React from "react";
import PropTypes from "prop-types";
import { Box, styled, IconButton, Typography } from "@mui/material";
import colors from "config/theme/colors";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useGetThumbnail } from "hooks/queries/useFile";
import SpinningCircle from "components/spinners/SpinningCircle";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import pdfIcon from "assets/adobePdfIcon.png";

const StyledItem = styled(Box)(() => ({
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  flex: "0 1 auto",
  "&:hover .MuiBox-root": {
    opacity: 1,
    visibility: "visible",
  },
  "&:hover::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "4px",
    backdropFilter: "blur(1px)",
    zIndex: 1,
  },
}));

const ActionButtons = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  opacity: 0,
  visibility: "hidden",
  transition: "opacity 0.3s, visibility 0.3s",
  zIndex: 2,
}));

const CarouselItem = ({ item, onDelete, onDownload, onPreview }) => {
  const {
    data: thumbnail,
    isLoading: thumbnailIsLoading,
    isError: thumbnailIsError,
  } = useGetThumbnail(item?.id, {
    enabled: !!item?.id,
  });

  const renderPreviewerBasedOnType = (file) => {
    if (thumbnailIsError) {
      return (
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          paddingY={2}
          gap={1}
        >
          {file.type?.includes("image") || file.mimeType?.includes("image") ? (
            <ImageOutlinedIcon fontSize="large" />
          ) : (
            <AttachFileOutlinedIcon fontSize="large" />
          )}

          <Typography
            color={colors.text}
            textAlign="center"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            maxWidth={"45%"}
            fontSize={10}
          >
            {file.title}
          </Typography>
        </Box>
      );
    } else if (
      file.type?.includes("image") ||
      file.type?.includes("pdf") ||
      file.mimeType?.includes("image") ||
      file.mimeType?.includes("pdf")
    ) {
      const blob = thumbnail
        ? new Blob([thumbnail], { type: "image/png" })
        : null;
      const thumbnailUrl = blob ? URL.createObjectURL(blob) : null;

      if (
        (file.type?.includes("pdf") || file.mimeType?.includes("pdf")) &&
        !thumbnail
      ) {
        const overlayText = file?.name;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={pdfIcon}
              alt={file.name}
              style={{ width: "80%", height: "100%", objectFit: "contain" }}
            />
            <div
              style={{
                color: colors.text,
                fontSize: "11px",
                zIndex: 2,
                maxLines: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {overlayText}
            </div>
          </div>
        );
      }

      return (
        <img
          src={thumbnailUrl || file?.preview}
          alt={file.name}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      );
    } else {
      return <Box>{file.name}</Box>;
    }
  };

  if (thumbnailIsLoading) {
    return (
      <StyledItem>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <SpinningCircle />
        </Box>
      </StyledItem>
    );
  }

  return (
    <StyledItem>
      {renderPreviewerBasedOnType(item)}
      <ActionButtons>
        {onDownload && (
          <IconButton
            size="small"
            onClick={() => onDownload(item)}
            title="Κατέβασε το αρχείο"
          >
            <FileDownloadOutlinedIcon
              fontSize="small"
              sx={{
                "&:hover": {
                  color: colors.primary,
                },
              }}
            />
          </IconButton>
        )}

        {onPreview && !onDelete && (
          <IconButton
            size="small"
            onClick={() => onPreview(item)}
            title="Προβολή αρχείου"
          >
            <VisibilityOutlinedIcon
              fontSize="small"
              sx={{
                "&:hover": {
                  color: colors.primary,
                },
              }}
            />
          </IconButton>
        )}

        {onDelete && (
          <IconButton
            size="small"
            onClick={() => onDelete(item)}
            title="Διαγραφή"
          >
            <DeleteOutlineOutlinedIcon
              fontSize="small"
              sx={{
                "&:hover": {
                  color: colors.primary,
                },
              }}
            />
          </IconButton>
        )}
      </ActionButtons>
    </StyledItem>
  );
};

CarouselItem.propTypes = {
  item: PropTypes.object,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
};

export default CarouselItem;
