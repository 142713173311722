import React, { useState } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import PropTypes from "prop-types";
import CustomButton from "components/buttons/CustomButton";
import { useAlert } from "hooks/useAlert";

const MassImportStepper = ({
  steps = [],
  disabledSubmitButton = false,
  handleSubmit = () => {},
  disabledNextButton = false,
  setFileData,
  filteredFileData = [],
  warningTitle,
}) => {
  const { showWarning } = useAlert();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (filteredFileData.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      showWarning(warningTitle);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setFileData(null);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps?.map((step, index) => (
          <Step key={step?.label + index}>
            <StepLabel>{step?.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {steps[activeStep]?.component}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 5,
            gap: 2,
            justifyContent: "end",
          }}
        >
          <CustomButton
            title="Πίσω"
            disabled={activeStep === 0}
            onClick={handleBack}
            orientation="row"
            fontSize={14}
            fontWeight={600}
            sx={{
              minWidth: 120,
              maxHeight: 20,
              paddingY: 3,
              borderRadius: 3,
            }}
          />
          {activeStep === steps.length - 1 ? (
            <CustomButton
              title="Υποβολή"
              disabled={disabledSubmitButton}
              onClick={handleSubmit}
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
              }}
            />
          ) : (
            <CustomButton
              title="Επόμενο"
              disabled={disabledNextButton}
              onClick={handleNext}
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

MassImportStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  disabledSubmitButton: PropTypes.bool,
  handleSubmit: PropTypes.func,
  disabledNextButton: PropTypes.bool,
  setFileData: PropTypes.func,
  filteredFileData: PropTypes.array,
  warningTitle: PropTypes.string,
};

export default MassImportStepper;
