import PropTypes from "prop-types";
import DataGridTable from "components/tables/DataGridTable";
import { Box, Tooltip, Typography, Switch } from "@mui/material";
import * as Yup from "yup";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import CustomButton from "components/buttons/CustomButton";
import enums from "config/enums";
import { useGetAllAssetCategories } from "hooks/queries/useAssetCategories";
import { fromAddress, setKey, setLanguage, setRegion } from "react-geocode";
import SpinningCircle from "components/spinners/SpinningCircle";

setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
setLanguage("el");
setRegion("GR");

const DataValidationStep = ({
  fileData,
  onValidationErrors,
  onFileDataUpdate,
  handleCloseUploadModal,
}) => {
  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [showErrorRowsOnly, setShowErrorRowsOnly] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const prevErrorsRef = useRef(null);
  const [fileDataWithLatLng, setFileDataWithLatLng] = useState(null);

  const {
    data: assetCategories,
    isLoading: assetCategoriesLoading,
    isError: assetCategoriesError,
  } = useGetAllAssetCategories();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      Όνομα: Yup.string()
        .strict()
        .required("Το όνομα ακινήτου είναι υποχρεωτικό"),
      ΚΑΕΚ: Yup.string().required("Ο ΚΑΕΚ είναι υποχρεωτικός"),
      Όροφοι: Yup.number()
        .required("Ο αριθμός των ορόφων είναι υποχρεωτικός")
        .typeError("Ο αριθμός των ορόφων πρέπει να είναι αριθμός"),
      Πόλη: Yup.string().required("Η πόλη είναι υποχρεωτική"),
      Διεύθυνση: Yup.string().required("Η διεύθυνση είναι υποχρεωτική"),
      ΤΚ: Yup.string().required("Ο ταχυδρομικός κώδικας είναι υποχρεωτικός"),
      "Χρήση ακινήτου": Yup.string()
        .required("Η χρήση ακινήτου είναι υποχρεωτική")
        .oneOf(
          enums.AssetUse.map((use) => use.label.split(" ")[0]),
          `Η χρήση ακινήτου πρέπει να είναι μία από τις επιλογές: ${enums.AssetUse.map(
            (use) => use.label.split(" ")[0]
          ).join(", ")}`
        ),
      "Επιφάνεια ακινήτου": Yup.number()
        .required("Η επιφάνεια είναι υποχρεωτική")
        .typeError("Η επιφάνεια πρέπει να είναι αριθμός"),
      Ιδιοκτησία: Yup.string()
        .required("Η ιδιοκτησία είναι υποχρεωτική")
        .oneOf(
          enums.Ownership.map((ownership) => ownership.label),
          `Η ιδιοκτησία πρέπει να είναι μία από τις επιλογές: ${enums.Ownership.map(
            (ownership) => ownership.label
          ).join(", ")}`
        ),
      Κατηγορία: Yup.string()
        .required("Η κατηγορία ακινήτου είναι υποχρεωτική")
        .oneOf(
          assetCategories?.items?.map((category) => category.description) || [],
          `Η κατηγορία ακινήτου πρέπει να είναι μία από τις επιλογές: ${assetCategories?.items
            ?.map((category) => category.description)
            .join(", ")}`
        ),
    });
  }, [assetCategories]);

  const geocodeData = async (data) => {
    const geocodedData = await Promise.all(
      data.map(async (item, index) => {
        const { Πόλη: city, Διεύθυνση: address, ΤΚ: postalCode } = item;
        const fullAddress = `${address}, ${city}, ${postalCode}`;
        try {
          const response = await fromAddress(fullAddress);
          const { lat, lng } = response.results[0].geometry.location;
          const postalCode = response.results[0].address_components.find(
            (component) => component.types.includes("postal_code")
          ).long_name;
          const city = response.results[0].address_components.find(
            (component) => component.types.includes("locality")
          ).long_name;
          const address = response.results[0].formatted_address;
          return {
            id: index,
            ...item,
            lat,
            lng,
            ΤΚ: postalCode,
            Πόλη: city,
            Διεύθυνση: address,
          };
        } catch (error) {
          console.error("Geocoding error:", error);
          return { ...item, lat: null, lng: null };
        }
      })
    );

    return geocodedData;
  };

  useEffect(() => {
    setFileDataWithLatLng(fileData);

    if (!fileData || fileDataWithLatLng) return;

    const fetchGeocodedData = async () => {
      const dataWithLatLng = await geocodeData(fileData);
      setFileDataWithLatLng(dataWithLatLng);
      onFileDataUpdate(dataWithLatLng.map((data) => ({ ...data })));
    };

    fetchGeocodedData();
  }, [fileData]);

  useEffect(() => {
    const validateData = async () => {
      if (assetCategoriesLoading || !assetCategories) {
        return;
      }

      const newErrors = {};
      const validatedRows = await Promise.all(
        fileDataWithLatLng.map(async (data, index) => {
          try {
            await validationSchema.validate(data, { abortEarly: false });
            return { id: index, ...data };
          } catch (err) {
            const errorMessages = err.inner.reduce((acc, error) => {
              acc[error.path] = error.message;
              return acc;
            }, {});
            newErrors[index] = errorMessages;
            return { id: index, ...data };
          }
        })
      );

      setRows(validatedRows);
      if (JSON.stringify(newErrors) !== JSON.stringify(prevErrorsRef.current)) {
        setErrors(newErrors);
        onValidationErrors(
          Object.keys(newErrors).length > 0 ? newErrors : null
        );
        prevErrorsRef.current = newErrors;
      }
    };

    if (fileDataWithLatLng) {
      validateData();
    }
  }, [
    fileDataWithLatLng,
    onValidationErrors,
    validationSchema,
    assetCategoriesLoading,
    assetCategories,
  ]);

  const validateRow = async (row) => {
    try {
      await validationSchema.validate(row, { abortEarly: false });
      return { id: row.id, ...row, errors: {} };
    } catch (err) {
      const errorMessages = err.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      return { id: row.id, ...row, errors: errorMessages };
    }
  };

  const handleCellEdit = async (updatedRow) => {
    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? { ...row, ...updatedRow } : row
    );
    if (
      updatedRow["Πόλη"] !== fileDataWithLatLng[updatedRow.id]["Πόλη"] ||
      updatedRow["Διεύθυνση"] !==
        fileDataWithLatLng[updatedRow.id]["Διεύθυνση"] ||
      updatedRow["ΤΚ"] !== fileDataWithLatLng[updatedRow.id]["ΤΚ"]
    ) {
      const fullAddress = `${updatedRow["Διεύθυνση"]}, ${updatedRow["Πόλη"]}, ${updatedRow["ΤΚ"]}`;
      try {
        const response = await fromAddress(fullAddress);
        const { lat, lng } = response.results[0].geometry.location;
        const postalCode = response.results[0].address_components.find(
          (component) => component.types.includes("postal_code")
        )?.long_name;
        const city = response.results[0].address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name;
        const address = response.results[0].formatted_address;
        updatedRow = {
          ...updatedRow,
          lat,
          lng,
          ΤΚ: postalCode,
          Πόλη: city,
          Διεύθυνση: address,
        };
      } catch (error) {
        console.error("Geocoding error:", error);
        updatedRow = { ...updatedRow, lat: null, lng: null };
      }
    }
    setRows(updatedRows);
    const updatedFileData = fileDataWithLatLng?.map((data, index) => {
      return index === updatedRow.id ? updatedRow : data;
    });
    onFileDataUpdate(updatedFileData);
    const validatedRows = await Promise.all(updatedFileData.map(validateRow));

    const newErrors = validatedRows.reduce((acc, row) => {
      if (row.errors && Object.keys(row.errors).length > 0) {
        acc[row.id] = row.errors;
      }
      return acc;
    }, {});

    setRows(validatedRows);
    if (JSON.stringify(newErrors) !== JSON.stringify(prevErrorsRef.current)) {
      setErrors(newErrors);
      onValidationErrors(Object.keys(newErrors).length > 0 ? newErrors : null);
      prevErrorsRef.current = newErrors;
    }

    if (showErrorRowsOnly && Object.keys(newErrors).length === 0) {
      setShowErrorRowsOnly(false);
    }
  };

  const handleDeleteRows = () => {
    const remainingRows = rows.filter((row) => !selectedRows.includes(row.id));
    const updatedFileData = remainingRows.map((row) => {
      const { id, ...rest } = row;
      return rest;
    });

    setRows(remainingRows);
    setSelectedRows([]);
    onFileDataUpdate(updatedFileData);

    if (remainingRows.length === 0) {
      handleCloseUploadModal();
    } else if (remainingRows.every((row) => !errors[row.id])) {
      setShowErrorRowsOnly(false);
    }
  };

  const getCellWidth = (key) => {
    switch (key) {
      case "Διεύθυνση":
        return 290;
      case "ΤΚ":
      case "Όροφοι":
        return 80;
      default:
        return 140;
    }
  };

  const columns = useMemo(
    () =>
      Object.keys(fileDataWithLatLng?.[0] || {})
        .filter((key) => key !== "id" && key !== "lat" && key !== "lng")
        .map((key) => ({
          field: key,
          headerName: key,
          width: getCellWidth(key),
          editable:
            key !== "Χρήση ακινήτου" &&
            key !== "Κατηγορία" &&
            key !== "Ιδιοκτησία",
          renderCell: (params) => {
            const rowError = errors[params.row.id] || {};
            const hasError = rowError[key] !== undefined;

            return (
              <Tooltip title={hasError ? rowError[key] : ""}>
                <Typography
                  sx={{
                    color: hasError ? "red" : "inherit",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginTop: 2,
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            );
          },
        })),
    [fileDataWithLatLng, errors]
  );

  const columnErrors = useMemo(() => {
    const errorMap = {};
    Object.values(errors).forEach((rowErrors) => {
      Object.keys(rowErrors).forEach((key) => {
        if (!errorMap[key]) {
          errorMap[key] = rowErrors[key];
        }
      });
    });
    return errorMap;
  }, [errors]);

  const errorKeys = Object.keys(columnErrors);
  const displayedErrors = showAllErrors ? errorKeys : errorKeys.slice(0, 2);
  const hasMoreErrors = errorKeys.length > 2;

  const filteredRows = showErrorRowsOnly
    ? rows.filter((row) => errors[row.id])
    : rows;

  const hasErrors = Object.keys(errors).length > 0;

  if (assetCategoriesError) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h6" sx={{ color: "red" }}>
          Σφάλμα κατά τη φόρτωση των κατηγοριών ακινήτων
        </Typography>
      </Box>
    );
  }

  return fileDataWithLatLng !== null ? (
    <Box width={"100%"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 1.5,
          marginLeft: 1,
        }}
      >
        {hasErrors && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              Εμφάνιση μόνο σειρών με σφάλματα
            </Typography>
            <Switch
              checked={showErrorRowsOnly}
              onChange={() => setShowErrorRowsOnly((prev) => !prev)}
            />
          </Box>
        )}

        {selectedRows.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CustomButton
              title="Διαγραφή επιλεγμένων σειρών"
              onClick={handleDeleteRows}
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
              }}
            />
          </Box>
        )}
      </Box>

      <DataGridTable
        sx={{
          width: "100%",
          marginTop: 3,
          padding: 0.5,
          maxHeight: "30vh",
          height: "100%",
          overflow: "auto",
        }}
        key={JSON.stringify(rows)}
        columns={columns}
        rows={filteredRows}
        onCellEdit={handleCellEdit}
        checkboxSelection={true}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
      />

      <Box sx={{ marginTop: 3, maxHeight: "25vh", overflowY: "auto" }}>
        {displayedErrors.map((key) => (
          <Box key={key} sx={{ marginBottom: 1, marginTop: 2 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {key}
            </Typography>
            <Typography sx={{ color: "red" }}>{columnErrors[key]}</Typography>
          </Box>
        ))}

        {hasMoreErrors && (
          <CustomButton
            orientation="row"
            fontSize={14}
            fontWeight={600}
            sx={{
              minWidth: 120,
              maxHeight: 20,
              paddingY: 3,
              borderRadius: 3,
              marginTop: 2,
            }}
            title={showAllErrors ? "Λιγότερα" : "Περισσότερα"}
            onClick={() => setShowAllErrors((prev) => !prev)}
          />
        )}
      </Box>
    </Box>
  ) : (
    <Box>
      <SpinningCircle />
    </Box>
  );
};

DataValidationStep.propTypes = {
  fileData: PropTypes.array,
  onValidationErrors: PropTypes.func.isRequired,
  onFileDataUpdate: PropTypes.func.isRequired,
  handleCloseUploadModal: PropTypes.func.isRequired,
};

export default DataValidationStep;
