import assetCategories from "./assetCategories";
import assets from "./assets";
import authSetup from "./authSetup";
import equipment from "./equipment";
import equipmentClass from "./equipmentClass";
import equipmentGroup from "./equipmentGroup";
import equipmentType from "./equipmentType";
import auth from "./auth";
import users from "./users";
import companies from "./companies";
import maintenanceType from "./maintenanceType";
import maintenance from "./maintenance";
import contracts from "./contracts";
import maintenanceHistory from "./maintenanceHistory";
import certificationType from "./certificationType";
import certificationClass from "./certificationClass";
import certifications from "./certifications";
import certificationHistory from "./certificationHistory";
import rents from "./rents";
import rentHistory from "./rentHistory";
import tasks from "./tasks";
import tickets from "./tickets";
import assetReports from "./assetReports";
import dashboard from "./dashboard";
import ticketReports from "./ticketReports";
import taskReports from "./taskReports";
import file from "./file";
import companyReports from "./companyReports";
import contractReports from "./contractReports";
import bulkImportEquipment from "./bulkImportEquipment";
import bulkImportAsset from "./bulkImportAsset";

export const Agent = {
  auth,
  assets,
  authSetup,
  assetCategories,
  users,
  equipmentType,
  equipmentGroup,
  equipmentClass,
  equipment,
  companies,
  maintenanceType,
  maintenance,
  contracts,
  maintenanceHistory,
  certificationType,
  certificationClass,
  certifications,
  certificationHistory,
  rents,
  rentHistory,
  tasks,
  tickets,
  assetReports,
  dashboard,
  ticketReports,
  taskReports,
  file,
  companyReports,
  contractReports,
  bulkImportEquipment,
  bulkImportAsset,
};
