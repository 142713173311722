import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import assetResponseFields from "config/forms/assetResponseFields";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import DeleteModal from "components/modals/DeleteModal";
import { useCallback, useState } from "react";
import AutocompleteMap from "components/maps/AutoCompleteMap";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import ExitFormModal from "components/modals/ExitFormModal";

const nonFloorOptions = ["Οικόπεδο", "Κοινόχρηστος χώρος / Πάρκο", "Ρέμα"];

const fileFieldDictionary = [
  "generalFiles",
  "complianceFiles",
  "financialFiles",
];

const AssetViewEditForm = ({
  selectedAsset,
  selectedTab,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateAsset"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const isDeleteLoading = useIsMutating({ mutationKey: ["deleteAsset"] }) > 0;

  const schema = yup.object(
    Object.keys(assetResponseFields).reduce((acc, key) => {
      assetResponseFields[key].forEach((field) => {
        let fieldSchema;

        if (field.type === "geoLocation") {
          fieldSchema = yup
            .object({
              formattedAddress: yup
                .string()
                .required("Το πεδίο είναι υποχρεωτικό"),
              lat: yup.number().required("Το πεδίο είναι υποχρεωτικό"),
              lng: yup.number().required("Το πεδίο είναι υποχρεωτικό"),
              postalCode: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
              city: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
            })
            .shape({
              formattedAddress: yup
                .string()
                .required("Το πεδίο είναι υποχρεωτικό"),
              lat: yup.number().required("Το πεδίο είναι υποχρεωτικό"),
              lng: yup.number().required("Το πεδίο είναι υποχρεωτικό"),
              postalCode: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
              city: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
            });
        } else if (field.type === "text" || field.type === "textarea") {
          fieldSchema = yup.string();
        } else if (field.type === "select" || field.type === "boolean") {
          fieldSchema = yup.string();
        } else if (field.type === "number") {
          fieldSchema = yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value));
        } else if (field.type === "date") {
          fieldSchema = yup.date().transform((value, originalValue) => {
            return isNaN(Date.parse(originalValue)) ? undefined : value;
          });
        } else if (field.type === "file") {
          const validTypes = field.validTypes || [];
          const maxSize = field.maxSize || 5000000;

          fieldSchema = yup.mixed().test({
            name: "fileValidation",
            exclusive: true,
            message: "Invalid file",
            test: (value) => {
              if (!value || value.length === 0) return true;

              for (const file of value) {
                if (
                  !validTypes.includes(file.type) &&
                  !validTypes.includes(file.mimeType)
                ) {
                  return new yup.ValidationError(
                    "Μη έγκυρος τύπος αρχείου",
                    null,
                    "file"
                  );
                }

                if (file.size > maxSize) {
                  return new yup.ValidationError(
                    `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                    null,
                    "file"
                  );
                }
              }

              return true;
            },
          });
        }

        if (field.required) {
          fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
        } else {
          fieldSchema = fieldSchema.nullable();
        }

        if (field.validation === "custom" && field.regex) {
          fieldSchema = fieldSchema.matches(
            field.regex,
            field.validationMessage
          );
        }

        acc[field.name] = fieldSchema.typeError(field.validationMessage);
      });
      return acc;
    }, {})
  );

  const getDataValueFromField = useCallback(
    (fieldName) => {
      const fieldMap = Object.keys(assetResponseFields).reduce((acc, key) => {
        assetResponseFields[key].forEach((field) => {
          acc[field.name] = field.name;
        });
        return acc;
      }, {});

      const path = fieldMap[fieldName];
      if (typeof path === "function") {
        return path();
      } else if (typeof path === "string") {
        if (fieldName === "geoLocation") {
          const lat = selectedAsset?.lat;
          const lng = selectedAsset?.long;
          const formattedAddress = selectedAsset?.address;
          const postalCode = selectedAsset?.postalCode;
          const city = selectedAsset?.city;
          return lat && lng && formattedAddress && postalCode && city
            ? { formattedAddress, lat, lng, postalCode, city }
            : null;
        } else if (fileFieldDictionary.includes(fieldName)) {
          return selectedAsset?.[fieldName];
        } else {
          return path
            .split(".")
            .reduce((acc, key) => (acc ? acc[key] : undefined), selectedAsset);
        }
      } else {
        return "";
      }
    },
    [selectedTab]
  );

  const getFormFieldsByTab = () => {
    switch (selectedTab) {
      case -1:
        return assetResponseFields.general;
      case 0:
        return assetResponseFields.legal;
      case 1:
        return assetResponseFields.financial;
      case 2:
        return assetResponseFields.technical;
      case 3:
        return assetResponseFields.energy;
      default:
        return assetResponseFields.general;
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formFields = getFormFieldsByTab();

  const defaultValues = {
    ...Object.keys(assetResponseFields).reduce((acc, key) => {
      assetResponseFields[key].forEach((field) => {
        acc[field.name] = getDataValueFromField(field.name);
      });
      return acc;
    }, {}),
    generalFiles: getDataValueFromField("generalFiles")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
    financialFiles: getDataValueFromField("financialFiles")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
    complianceFiles: getDataValueFromField("complianceFiles")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return (
        <CustomTextField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
          isLink={field.isLink}
        />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      const availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;
      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "geoLocation") {
      const location = defaultValue || {
        formattedAddress: "",
        lat: null,
        lng: null,
        postalCode: "",
        city: "",
      };
      return (
        <Controller
          name={field.name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <AutocompleteMap
              marker={value || location}
              error={!!error}
              onMarkerChange={onChange}
              readOnly={!isEditing}
              containerStyle={{
                width: "100%",
                height: "400px",
              }}
              inputFieldLabel={field.label}
              required={field.required}
            />
          )}
        />
      );
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  const handleSubmitValues = () => {
    const { generalFiles, complianceFiles, financialFiles, ...restValues } =
      getValues();
    const formattedValues = {
      ...restValues,
      generalFiles: generalFiles.map((file) => ({
        ...file,
        groupTag: "General",
      })),
      complianceFiles: complianceFiles.map((file) => ({
        ...file,
        groupTag: "Compliance",
      })),
      financialFiles: financialFiles.map((file) => ({
        ...file,
        groupTag: "Financial",
      })),
    };
    onSave(formattedValues);
  };

  return (
    <>
      <form onSubmit={handleSubmit(() => handleSubmitValues())}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Grid container spacing={2}>
            {formFields
              .filter((field) => field.display !== false)
              .map((field) => {
                // Skip rendering floorCount field if asset included at the nonFloorOptions list
                if (
                  field.name === "floorCount" &&
                  nonFloorOptions.includes(
                    selectedAsset.assetCategory.description
                  )
                ) {
                  return null;
                }

                if (field.name === "geoLocation") {
                  return (
                    <Grid item xs={12} key={field.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  );
                } else if (field.type === "file") {
                  return (
                    <Grid item xs={12} key={field?.name}>
                      <Box width={{ xs: "100%", lg: "50%" }}>
                        {getInputElementByFieldType(field)}
                      </Box>
                    </Grid>
                  );
                } else
                  return (
                    <Grid item xs={12} md={4} key={field.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  );
              })}
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                onClick={() => {
                  reset();
                  onCancel();
                }}
                disabled={!isEditing}
              />
              <DeleteButton
                onClick={handleOpenModal}
                text="Διαγραφή Ακινήτου"
                disabled={!isEditing}
                isLoading={isDeleteLoading}
              />
            </Box>

            <SaveButton
              disabled={!isEditing || !isDirty}
              isLoading={isUpdateLoading}
            />

            <DeleteModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={onDelete}
              itemTitle={selectedAsset?.name}
              title="Πρόκειται να διαγράψετε το ακίνητο"
              description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του ακινήτου;"
            />
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

AssetViewEditForm.propTypes = {
  selectedAsset: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AssetViewEditForm;
