import { Box, Button, CircularProgress, Typography } from "@mui/material";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const CustomButton = forwardRef(
  (
    {
      icon = null,
      onClick,
      type = "button",
      disabled = false,
      orientation = "column",
      className,
      id,
      sx = {},
      title,
      subtitle,
      fontSize = 16,
      fontWeight = 700,
      isLoading = false,
    },
    ref
  ) => {
    return (
      <Button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={className}
        id={id}
        sx={{ borderRadius: 4, paddingY: 4, ...sx }}
        variant="contained"
        ref={ref}
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection={orientation}
          alignItems="center"
          gap={1}
          sx={{
            height: "100%",
          }}
        >
          {icon}
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100px",
              }}
            >
              <CircularProgress
                size={20}
                sx={{
                  color: colors.white,
                }}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flexWrap={"wrap"}
            >
              <Typography fontWeight={fontWeight} fontSize={fontSize}>
                {title}
              </Typography>
              {subtitle && (
                <Typography fontSize={fontSize - 4} color={colors.lightGrey}>
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Button>
    );
  }
);

CustomButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.node,
  sx: PropTypes.object,
  title: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  orientation: PropTypes.oneOf(["row", "column"]),
  isLoading: PropTypes.bool,
  subtitle: PropTypes.string,
};

export default CustomButton;
