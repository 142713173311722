import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { elGR } from "@mui/x-date-pickers/locales";
import AppContent from "components/main/AppContent";
import theme from "config/theme/theme";
import el from "dayjs/locale/el";

const greekLocale =
  elGR.components.MuiLocalizationProvider.defaultProps.localeText;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={el}
        localeText={{
          ...greekLocale,
          fieldYearPlaceholder: (params) => "Ε".repeat(params.digitAmount),
          fieldMonthPlaceholder: () => "ΜΜ",
          fieldDayPlaceholder: () => "ΗΗ",
          fieldHoursPlaceholder: () => "ωω",
          fieldMinutesPlaceholder: () => "λλ",
          fieldSecondsPlaceholder: () => "δδ",
          fieldMeridiemPlaceholder: () => "πμ",
        }}
      >
        <AppContent />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
