import React, { useState } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import PropTypes from "prop-types";
import CustomButton from "components/buttons/CustomButton";

const BasicStepper = ({ steps = [] }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = async () => {
    const onNextCallbackResult = steps[activeStep]?.onNextCallback?.();

    if (onNextCallbackResult instanceof Promise) {
      try {
        setIsLoading(true);
        await onNextCallbackResult;
        setIsLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps?.map((step, index) => (
          <Step key={step.id}>
            <StepLabel>{`Βήμα ${index + 1}`}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            paddingLeft: 2,
          }}
        >
          {steps[activeStep]?.component}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 5,
            gap: 2,
            justifyContent: "end",
          }}
        >
          <>
            <CustomButton
              title="Πίσω"
              disabled={
                activeStep === 0 || steps[activeStep]?.disableBackButton
              }
              onClick={handleBack}
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                minWidth: 120,
                maxHeight: 20,
                paddingY: 3,
                borderRadius: 3,
              }}
            />
            {(activeStep !== steps.length - 1 ||
              steps[activeStep]?.nextButtonText) && (
              <CustomButton
                title={steps[activeStep]?.nextButtonText || "Επόμενο"}
                disabled={steps[activeStep]?.disableNextButton || false}
                isLoading={isLoading}
                onClick={handleNext}
                fontSize={14}
                fontWeight={600}
                sx={{
                  minWidth: 120,
                  maxHeight: 20,
                  paddingY: 3,
                  borderRadius: 3,
                }}
              />
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

BasicStepper.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default BasicStepper;
