import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import { useCallback, useEffect, useState } from "react";
import colors from "config/theme/colors";
import {
  useGetBulkAssetTemplate,
  useImportBulkAsset,
} from "hooks/queries/useGetBulkAssetTemplate";
import MainPageLayout from "components/main/MainPageLayout";
import { useAlert } from "hooks/useAlert";
import MassImportStepperModal from "components/modals/MassImportStepperModal";
import AssetMassImportErrorModal from "./AssetMassImportErrorModal";
import UploadFileStep from "./Steps/UploadFileStep";
import DataValidationStep from "./Steps/DataValidationStep";
import { useGetAllAssetCategories } from "hooks/queries/useAssetCategories";
import SpinningCircle from "components/spinners/SpinningCircle";
import enums from "config/enums";

const warningTitle = "Το αρχείο που επιλέξατε είναι κενό";

const AssetMassImport = () => {
  const { showError, showSuccess } = useAlert();
  const [selectedExportType, setSelectedExportType] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [errorsResponse, setErrorsResponse] = useState([]);
  const [submitData, setSubmitData] = useState(null);
  const [errorsModalOpen, setErrorsModalOpen] = useState(false);
  const [errorsModalTitle, setErrorsModalTitle] = useState("");

  const {
    data: assetCategories,
    isLoading: assetCategoriesLoading,
    isError: assetCategoriesError,
  } = useGetAllAssetCategories(-1, -1, {
    select: (data) => data?.result?.items || [],
  });

  const { mutate: importBulkAsset } = useImportBulkAsset({
    config: {
      onError: (error) => {
        showError(`Η εισαγωγή απέτυχε: ${error.message}`);
      },
      onSuccess: (res) => {
        const { result } = res;
        const { errorsResponse } = result || {};

        if (errorsResponse && errorsResponse.length > 0) {
          setErrorsModalTitle("Η εισαγωγή ολοκληρώθηκε με προβλήματα");
          setErrorsModalOpen(true);
          setErrorsResponse(errorsResponse);
        } else {
          showSuccess("Η εισαγωγή ολοκληρώθηκε με επιτυχία");
        }

        setFileData(null);
      },
    },
  });

  const {
    data: templateData,
    isLoading: templateLoading,
    isError: templateError,
    refetch: refetchTemplateData,
  } = useGetBulkAssetTemplate();

  const handleExport = useCallback((type) => {
    setSelectedExportType(type);
  }, []);

  const handleErrorsModalClose = () => {
    setErrorsModalOpen(false);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const adjustedFileData = fileData?.map((item) => ({
    name: item["Όνομα"] || "",
    kaek: item["ΚΑΕΚ"] || "",
    floorCount: item["Όροφοι"] || "",
    city: item["Πόλη"] || "",
    address: item["Διεύθυνση"] || "",
    postalCode: item["ΤΚ"]?.toString() || "",
    areaSize: item["Επιφάνεια ακινήτου"] || "",
    ownership:
      enums.Ownership?.find(
        (ownership) => ownership.label === item["Ιδιοκτησία"]
      )?.id || "",
    assetUse:
      enums.AssetUse?.find(
        (use) => use.label.split(" ")[0] === item["Χρήση ακινήτου"]
      )?.id || "",
    assetCategoryId:
      assetCategories?.find(
        (category) => category.description === item["Κατηγορία"]
      )?.id || "",
    lat: item["lat"] || null,
    long: item["lng"] || null,
  }));

  const handleSubmit = () => {
    if (fileData && !validationErrors) {
      const payload = adjustedFileData?.map((item) => ({
        ...item,
        name: item.name?.toString(),
        kaek: item.kaek?.toString(),
        address: item.address?.toString(),
        city: item.city?.toString(),
        postalCode: item.postalCode?.toString(),
        floorCount: parseInt(item.floorCount) || 0,
      }));

      setSubmitData(payload);
      importBulkAsset(payload);
    }
  };

  const handleFileUpload = (data) => {
    setFileData(data);
    setValidationErrors(null);
  };

  const handleValidationErrors = (errors) => {
    setValidationErrors(errors);
  };

  const handleFileDataUpdate = (updatedFileData) => {
    setFileData(updatedFileData);
  };

  const handleDownloadSample = useCallback(() => {
    if (!templateData) return;
    const url = window.URL.createObjectURL(new Blob([templateData]));
    const link = document.createElement("a");
    link.href = url;
    link.download = `sample.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setSelectedExportType(null);
  }, [templateData]);

  useEffect(() => {
    if (selectedExportType) {
      refetchTemplateData();
    }
  }, [selectedExportType]);

  useEffect(() => {
    if (selectedExportType && templateData) {
      handleDownloadSample();
    }
    if (templateError) {
      showError("Παρουσιάστηκε πρόβλημα κατά την εξαγωγή του αρχείου.");
    }
  }, [templateData, selectedExportType, templateError, handleDownloadSample]);

  const disabledSubmitButton = !!validationErrors;
  const disabledNextButton = !fileData;
  const filteredFileData = (data) => {
    return data?.filter((row) => !Object.values(row)?.includes("Επιλέξτε"));
  };

  const steps = [
    {
      label: "Μεταφόρτωση Αρχείου",
      component: <UploadFileStep onUpload={handleFileUpload} />,
    },
    {
      label: "Επικύρωση Δεδομένων",
      component: (
        <DataValidationStep
          fileData={fileData ? filteredFileData(fileData) : []}
          onValidationErrors={handleValidationErrors}
          onFileDataUpdate={handleFileDataUpdate}
          handleCloseUploadModal={handleCloseUploadModal}
        />
      ),
    },
  ];

  if (assetCategoriesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (assetCategoriesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:
          {assetCategoriesError?.message}
        </p>
      </Box>
    );
  }

  return (
    <MainPageLayout
      pageTitle={"Νέο Ακίνητο"}
      pageSubTitle={"Μαζική Προσθήκη Ακινήτων"}
    >
      <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          justifyContent={"flex-start"}
          alignItems="flex-start"
          width={"100%"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"flex-start"}
            alignItems="flex-start"
            width={"100%"}
          >
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={1}
              justifyContent={"flex-start"}
              alignItems="baseline"
              width={"100%"}
              flexWrap={"wrap"}
            >
              <Typography color={colors.primary} fontWeight={700} fontSize={18}>
                Βήμα 1
              </Typography>
              <Typography color={colors.text} fontWeight={400} fontSize={14}>
                Κατεβάστε το πρότυπο αρχείο και συμπληρώστε τα δεδομένα
              </Typography>
            </Box>
            <CustomButton
              title="Λήψη πρότυπου αρχείου"
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                borderRadius: 3,
                paddingY: 1.8,
                width: { xs: "100%", md: 300 },
              }}
              onClick={() => handleExport("excel")}
              disabled={templateLoading || templateError}
              isLoading={templateLoading}
              icon={<FileDownloadOutlinedIcon />}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"flex-start"}
            alignItems="flex-start"
            width={"100%"}
          >
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={1}
              justifyContent={"flex-start"}
              alignItems="baseline"
              width={"100%"}
              flexWrap={"wrap"}
            >
              <Typography color={colors.primary} fontWeight={700} fontSize={18}>
                Βήμα 2
              </Typography>
              <Typography color={colors.text} fontWeight={400} fontSize={14}>
                Μεταφορτώστε το αρχείο και επικυρώστε τα δεδομένα
              </Typography>
            </Box>

            <CustomButton
              title="Μεταφόρτωση αρχείου και εισαγωγή δεδομένων"
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                borderRadius: 3,
                paddingY: 1.8,
                width: { xs: "100%", md: 300 },
              }}
              onClick={handleOpenUploadModal}
              icon={<FileUploadOutlinedIcon />}
            />
          </Box>
        </Box>

        <Box>
          <MassImportStepperModal
            isOpen={isUploadModalOpen}
            handleClose={handleCloseUploadModal}
            steps={steps}
            handleSubmit={handleSubmit}
            disabledSubmitButton={disabledSubmitButton}
            disabledNextButton={disabledNextButton}
            filteredFileData={filteredFileData(fileData)}
            setFileData={setFileData}
            warningTitle={warningTitle}
          />

          <AssetMassImportErrorModal
            modalOpen={errorsModalOpen}
            handleModalClose={handleErrorsModalClose}
            modalTitle={errorsModalTitle}
            errorsResponse={errorsResponse || []}
            fileData={submitData || []}
            assetCategories={assetCategories || []}
          />
        </Box>
      </Box>
    </MainPageLayout>
  );
};

export default AssetMassImport;
