import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import DeleteButton from "components/buttons/DeleteButton";
import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/inputs/CustomTextField";
import colors from "config/theme/colors";
import { useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import DeleteModal from "components/modals/DeleteModal";
import ExitFormModal from "components/modals/ExitFormModal";

const CertificationClassForm = ({
  isCreateForm = false,
  selectedCertificationClass,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCreatingLoading =
    useIsMutating({
      mutationKey: ["createCertificationClass"],
    }) > 0;
  const isUpdateLoading =
    useIsMutating({
      mutationKey: ["updateCertificationClass"],
    }) > 0;
  const isDeleteLoading =
    useIsMutating({
      mutationKey: ["deleteCertificationClass"],
    }) > 0;

  const schema = yup.object({
    name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    description: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const defaultValues = selectedCertificationClass
      ? {
          name: selectedCertificationClass.name || "",
          description: selectedCertificationClass.description || "",
        }
      : {
          name: "",
          description: "",
        };
    reset(defaultValues);
  }, [selectedCertificationClass, reset]);

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const disabled = !isEditing && !isCreateForm;

  return (
    <>
      <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography fontSize={24} fontWeight={600} color={colors.secondary}>
            {isCreateForm
              ? "Δημιουργία Κλάσης"
              : `${selectedCertificationClass?.name} - ${isEditing ? "Επεξεργασία" : "Προβολή"}`}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                name="name"
                label="Όνομα"
                type="text"
                value={selectedCertificationClass?.name}
                control={control}
                disabled={disabled}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                name="description"
                label="Περιγραφή"
                type="textarea"
                value={selectedCertificationClass?.description}
                control={control}
                disabled={disabled}
              />
            </Grid>
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              {isCreateForm && isDirty && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                  disabled={disabled}
                />
              )}
              {!isCreateForm && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                />
              )}
              {!isCreateForm && isEditing && (
                <DeleteButton
                  onClick={handleOpenModal}
                  text="Διαγραφή Κλάσης"
                  disabled={disabled}
                  isLoading={isDeleteLoading}
                />
              )}
            </Box>

            {(isEditing || isCreateForm) && (
              <SaveButton
                disabled={disabled || !isDirty}
                text={isCreateForm ? "Δημιουργία" : "Αποθήκευση"}
                isLoading={isUpdateLoading || isCreatingLoading}
              />
            )}

            {isEditing && (
              <DeleteModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onDelete={onDelete}
                itemTitle={selectedCertificationClass?.name}
                title="Πρόκειται να διαγράψετε την κλάση"
                description="Σιγουρευτείτε ότι η συγκεκριμένη κλάση δεν χρησιμοποιείται σε κάποιον τύπο πιστοποίησης. Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή της κλάσης;"
              />
            )}
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

CertificationClassForm.propTypes = {
  isCreateForm: PropTypes.bool,
  selectedCertificationClass: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default CertificationClassForm;
