import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tooltip,
  Typography,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { InfoOutlined } from "@mui/icons-material";
import colors from "config/theme/colors";
import { Controller } from "react-hook-form";

const DropdownField = ({
  label,
  options,
  name,
  value: propValue,
  onChange,
  disabled = false,
  required = false,
  control,
  editable = true,
  boolean = false,
  multiple = false,
  sx,
}) => {
  const [value, setValue] = useState(propValue);

  const getOptionLabel = (selectedOption) => {
    const found = Boolean(
      options?.find((opt) => {
        if (typeof selectedOption === "object") {
          return opt.label === selectedOption.label;
        }
        return opt === selectedOption;
      })
    );

    if (found) {
      return typeof selectedOption === "object"
        ? selectedOption.label
        : selectedOption;
    }

    const foundOption = options?.find((opt) => opt.id === selectedOption);

    if (foundOption) {
      return foundOption.label;
    }

    if (boolean) {
      return selectedOption ? "Ναι" : "Όχι";
    }

    return "";
  };

  const isOptionEqualToValue = (option, value) => {
    if (boolean) {
      return option === (value ? "Ναι" : "Όχι");
    } else if (typeof option === "object" && typeof value === "object") {
      return option.id === value.id;
    } else if (typeof option === "object") {
      return option.id === value;
    }
    return option === value;
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
    if (onChange) {
      if (boolean) {
        onChange(
          _,
          newValue === "Ναι" ? true : newValue === "Όχι" ? false : null
        );
      } else {
        onChange(_, newValue);
      }
    }
  };

  const getBooleanValue = (value) => {
    if (value !== null && value !== undefined) {
      return value ? "Ναι" : "Όχι";
    }
    return null;
  };

  const getRenderedValue = (value, isMultipleChoice) => {
    if (value) {
      return value;
    } else if (isMultipleChoice) {
      return [];
    }
    return null;
  };

  const renderAutocomplete = () => {
    const filterOptions = (options, fieldValue) => {
      if (multiple && fieldValue) {
        const filtered = options.filter(
          (option) =>
            !fieldValue.find(
              (val) =>
                (val.id !== undefined && option.id === val.id) ||
                (val.label !== undefined && option.label === val.label)
            )
        );
        return filtered;
      }
      return options;
    };

    if (control) {
      return (
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? "Υποχρεωτικό πεδίο" : false,
            validate:
              multiple && required
                ? (value) => (value && value.length > 0) || "Υποχρεωτικό πεδίο"
                : undefined,
          }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={filterOptions(options, field.value) || []}
              value={
                boolean
                  ? getBooleanValue(field.value)
                  : getRenderedValue(field.value, multiple)
              }
              multiple={multiple}
              onChange={
                boolean
                  ? (_, newValue) => {
                      handleChange(_, newValue);
                      const value = newValue === "Ναι";
                      field.onChange(value);
                    }
                  : (e, newValue) => {
                      handleChange(e, newValue);
                      field.onChange(newValue);
                    }
              }
              isOptionEqualToValue={isOptionEqualToValue}
              readOnly={disabled || !editable}
              popupIcon={<ExpandMoreOutlinedIcon />}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={(option) => option.isDisabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Επιλέξτε..."
                  error={!!error}
                  helperText={error ? error.message : null}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: disabled || !editable,
                    style: {
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#000000",
                      ...sx,
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: disabled || !editable,
                  }}
                />
              )}
              renderTags={(value, getTagProps) => {
                return value?.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  const renderOption = options.find(
                    (opt) =>
                      opt.id === option.id ||
                      opt.label === option.label ||
                      opt === option ||
                      opt === option.id ||
                      opt.id === option
                  )?.label;
                  return <Chip key={key} label={renderOption} {...tagProps} />;
                });
              }}
            />
          )}
        />
      );
    } else {
      return (
        <Autocomplete
          options={filterOptions(options, value)}
          value={boolean ? getBooleanValue(value) : value}
          onChange={
            boolean
              ? (_, newValue) => {
                  const value = newValue === "Ναι";
                  handleChange(_, value);
                }
              : handleChange
          }
          multiple={multiple}
          isOptionEqualToValue={isOptionEqualToValue}
          disabled={disabled || !editable}
          popupIcon={<ExpandMoreOutlinedIcon />}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={(option) => option.isDisabled}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly: disabled || !editable,
                style: {
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000000",
                  ...sx,
                },
              }}
              inputProps={{
                ...params.inputProps,
                readOnly: disabled || !editable,
              }}
            />
          )}
        />
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} width={"100%"}>
      <Typography
        fontSize={12}
        fontWeight={700}
        display="flex"
        alignItems="center"
        gap={1}
      >
        {label} {required && "*"}
        {!editable && (
          <Tooltip title="Μη επεξεργάσιμο πεδίο" placement="right">
            <InfoOutlined
              fontSize="small"
              sx={{
                color: colors.primary,
                cursor: "pointer",
                maxHeight: "0.9em",
              }}
            />
          </Tooltip>
        )}
      </Typography>
      {renderAutocomplete()}
    </Box>
  );
};

DropdownField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  control: PropTypes.object,
  editable: PropTypes.bool,
  sx: PropTypes.object,
  boolean: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default DropdownField;
