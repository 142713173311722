import { Box, Typography } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import ContractViewEditForm from "./ContractViewEditForm";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useDeleteContract,
  useGetContractById,
  useUpdateContract,
} from "hooks/queries/useContracts";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import enums from "config/enums";
import dayjs from "dayjs";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";
import { useAuthStore } from "stores/AuthStore";
import { useGetAllAssets } from "hooks/queries/useAssets";
import CustomButton from "components/buttons/CustomButton";

const ContractViewEdit = () => {
  const authStore = useAuthStore();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { showError, showSuccess, showWarning } = useAlert();
  const {
    data: relatedAssets,
    isLoading: isLoadingAssets,
    isError: isErrorAssets,
  } = useGetAllAssets(
    {
      contractId,
    },
    {
      enabled: !!contractId && !isDeleted,
      select: (data) => data.result?.items,
    }
  );
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: contractId,
    fileableType: "Contract",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();
  const {
    data: selectedContract,
    isLoading,
    isError,
    error,
  } = useGetContractById(contractId, {
    select: (data) => data.result,
    enabled: !!contractId && !isDeleted,
  });
  const { mutate: deleteContract } = useDeleteContract({
    contractId,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        setIsDeleted(true);
        setIsEditing(false);
        showSuccess("Η σύμβαση διαγράφηκε με επιτυχία");
        navigate("/contracts");
      },
    },
  });
  const { mutate: updateContract } = useUpdateContract({
    contractId,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η σύμβαση ενημερώθηκε με επιτυχία");
        setIsEditing(false);
      },
    },
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateContract(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateContract(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateContract(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    const payload = {
      name: data.name,
      number: data.number,
      description: data.description,
      validFrom: dayjs(data.validFrom).format("YYYY-MM-DD"),
      validTo: dayjs(data.validTo).format("YYYY-MM-DD"),
      type: enums.ContractTypes?.find((type) => type.label === data.type)?.id,
      status: data.status?.id,
      externalCompanyId: data.externalCompanyId,
    };

    const newFiles = data.files?.filter((file) =>
      selectedContract.files.every((attachment) => attachment.id !== file.id)
    );

    const deletedFiles = selectedContract.files?.filter(
      (attachment) => !data.files?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateContract(payload);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(payload, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(payload, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(payload, deletedFiles, newFiles);
    }
  };

  const handleDeleteClick = () => {
    if (selectedContract.hasMaintenances) {
      showWarning(
        "Η σύμβαση δεν μπορεί να διαγραφεί γιατί έχει συνδεθεί με συντηρήσεις. Παρακαλώ αφαιρέστε τις συντηρήσεις πρώτα."
      );
      return;
    }

    if (selectedContract.files.length === 0) {
      deleteContract();
    } else {
      deleteMultipleFiles(
        selectedContract.files.map((file) => file.id),
        {
          onSuccess: () => {
            deleteContract();
          },
          onError: (error) => {
            showWarning("Υπήρξε πρόβλημα με τη διαγραφή των αρχείων");
            showError(error.message);
            setIsDeleted(false);
          },
        }
      );
    }
  };

  return (
    selectedContract && (
      <MainPageLayout pageTitle={"Διαχείριση Σύμβασης"}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedContract.name}`}
              </Typography>
            </Box>
            {!authStore.isUser && (
              <Box
                display="flex"
                gap={2}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <EditButton onClick={handleEditClick} disabled={isEditing} />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Box mt={1} flexDirection="column" justifyContent="space-between">
              <ContractViewEditForm
                selectedContract={selectedContract}
                isEditing={isEditing}
                onSave={handleSaveClick}
                onCancel={handleCancelClick}
                onDelete={handleDeleteClick}
              />
            </Box>

            {relatedAssets?.length > 0 && (
              <>
                <Box
                  sx={{
                    borderLeft: "2px solid",
                    borderColor: colors.lightGrey,
                    borderRadius: 6,
                    opacity: "0.2",
                    marginLeft: 2,
                  }}
                />
                <Box
                  sx={{
                    width: "60%",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Συνδεδεμένα Ακίνητα
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontSize={12}
                    gutterBottom
                  >
                    Που σχετίζονται με τη σύμβαση μέσω των συντηρήσεων ή/και των
                    συντηρήσεων εξοπλισμού ή/και των ενοικίων
                  </Typography>
                  {isLoadingAssets ? (
                    <SpinningCircle />
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      sx={{
                        marginTop: 2,
                        height: "90%",
                        overflowY: "auto",
                      }}
                    >
                      {!isErrorAssets ? (
                        relatedAssets.map((asset) => (
                          <CustomButton
                            key={asset.id}
                            onClick={() => navigate(`/assets/${asset.id}`)}
                            title={asset.name}
                            subtitle={asset.address}
                            fontSize={16}
                            sx={{
                              backgroundColor: colors.primary,
                              color: colors.white,
                              border: "1px solid",
                              borderColor: colors.primary,
                              shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              paddingY: 1,
                              "&:hover": {
                                backgroundColor: colors.white,
                                color: colors.primary,
                              },
                            }}
                          />
                        ))
                      ) : (
                        <Typography variant="body1" color="error">
                          Υπήρξε πρόβλημα με την ανάκτηση των ακινήτων που
                          σχετίζονται με τη σύμβαση
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </MainPageLayout>
    )
  );
};

export default ContractViewEdit;
