import { requests } from "api/HttpClient";

const { getFile, post } = requests;

const bulkImportAsset = {
  getBulkAssetTemplate: () => {
    const url = "/BulkImportAsset/GetBulkAssetTemplate";
    return getFile(url);
  },
  importBulkAsset: (body) =>
    post("/BulkImportAsset/ImportBulkAsset", body, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
};

export default bulkImportAsset;
