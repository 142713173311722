import AssetCreate from "pages/assets/Create/AssetCreate";
import AssetsOverview from "pages/assets/Overview/AssetsOverview";
import AssetViewEditPageParent from "pages/assets/ViewEdit/AssetViewEditPageParent";
import AssetViewEdit from "pages/assets/ViewEdit/AssetViewEdit";
import CustomizationParent from "pages/customization/CustomizationParent";
import EquipmentConfiguration from "pages/customization/equipment/EquipmentConfiguration";
import EquipmentClassesOverview from "pages/customization/equipment/classes/EquipmentClassesOverview";
import EquipmentGroupsOverview from "pages/customization/equipment/groups/EquipmentGroupsOverview";
import EquipmentTypesOverview from "pages/customization/equipment/types/EquipmentTypesOverview";
import EquipmentCreate from "pages/equipment/Create/EquipmentCreate";
import EquipmentOverview from "pages/equipment/Overview/EquipmentOverview";
import EquipmentViewEdit from "pages/equipment/ViewEdit/EquipmentViewEdit";
import NoAccessPage from "pages/special/NoAccessPage";
import NoContentPage from "pages/special/NoContentPage";
import UserCreate from "pages/users/Create/UserCreate";
import UsersOverview from "pages/users/Overview/UsersOverview";
import UserViewEdit from "pages/users/ViewEdit/UserViewEdit";
import CompanyCreate from "pages/companies/Create/CompanyCreate";
import CompaniesOverview from "pages/companies/Overview/CompaniesOverview";
import CompanyViewEdit from "pages/companies/ViewEdit/CompanyViewEdit";
import ProfileViewEdit from "pages/profile/ViewEdit/ProfileViewEdit";
import { Navigate, Route, Routes } from "react-router";
import { observer } from "mobx-react-lite";
import { useAuthStore } from "stores/AuthStore";
import MaintenanceConfiguration from "pages/customization/maintenace/MaintenanceConfiguration";
import MaintenanceTypesOverview from "pages/customization/maintenace/types/MaintenanceTypesOverview";
import MaintenanceOverview from "pages/maintenances/Overview/MaintenanceOverview";
import MaintenanceCreate from "pages/maintenances/Create/MaintenanceCreate";
import MaintenanceViewEdit from "pages/maintenances/ViewEdit/MaintenanceViewEdit";
import MaintenanceHistoryCreate from "pages/maintenanceHistory/Create/MaintenanceHistoryCreate";
import MaintenanceHistoryViewEdit from "pages/maintenanceHistory/ViewEdit/MaintenanceHistoryViewEdit";
import CertificationConfiguration from "pages/customization/certification/CertificationConfiguration";
import CertificationTypesOverview from "pages/customization/certification/types/CertificationTypesOverview";
import CertificateClassesOverview from "pages/customization/certification/classes/CertificationClassesOverview";
import EquipmentViewEditParent from "pages/equipment/ViewEdit/EquipmentViewEditParent";
import CertificationViewEdit from "pages/certifications/ViewEdit/CertificationViewEdit";
import CertificationCreate from "pages/certifications/Create/CertificationCreate";
import CertificationOverview from "pages/certifications/Overview/CertificationOverview";
import CertificationHistoryViewEdit from "pages/certificationHistory/ViewEdit/CertificationHistoryViewEdit";
import CertificationHistoryCreate from "pages/certificationHistory/Create/CertificationHistoryCreate";
import RentOverview from "pages/rents/Overview/RentOverview";
import RentsCreate from "pages/rents/Create/RentsCreate";
import RentHistoryCreate from "pages/rentHistory/Create/RentHistoryCreate";
import RentHistoryViewEdit from "pages/rentHistory/ViewEdit/RentHistoryViewEdit";
import ContractViewEdit from "pages/contracts/ViewEdit/ContractViewEdit";
import ContractsOverview from "pages/contracts/Overview/ContractsOverview";
import ContractCreate from "pages/contracts/Create/ContractCreate";
import AssetContractCreate from "pages/contracts/Create/AssetContractCreate";
import AssetContractViewEdit from "pages/contracts/ViewEdit/AssetContractViewEdit";
import AssetContractsOverview from "pages/contracts/Overview/AssetContractsOverview";
import CompanyContractViewEdit from "pages/contracts/ViewEdit/CompanyContractViewEdit";
import TasksOverview from "pages/tasks/Overview/TasksOverview";
import TasksViewEdit from "pages/tasks/ViewEdit/TasksViewEdit";
import TicketsOverview from "pages/tickets/Overview/TicketsOverview";
import TicketViewEdit from "pages/tickets/ViewEdit/TicketViewEdit";
import TicketCreate from "pages/tickets/Create/TicketCreate";
import Reports from "pages/reports/Reports";
import Dashboard from "pages/dashboard/Dashboard";
import EquipmentMassImport from "pages/equipment/MassImport/ΕquipmentMassImport";
import AssetMassImport from "pages/assets/MassImport/AssetMassImport";

const AuthenticatedRoutes = () => {
  const authStore = useAuthStore();

  const getHomepagePath = () => {
    if (authStore.isSuperAdmin || authStore.isAdmin || authStore.isManager) {
      return "/dashboard";
    }

    if (authStore.isUser) {
      return "/assets";
    }

    if (authStore.isTechnicalIssues) {
      return "/technical-issues";
    }

    return "/restricted";
  };

  const getRoleBasedRoutes = () => {
    const defaultRoutes = (
      <>
        <Route path="/" element={<Navigate to={getHomepagePath()} />} />
        <Route path="/profile" element={<ProfileViewEdit />} />
        <Route path="/restricted" element={<NoAccessPage />} />
        <Route path="*" element={<NoContentPage />} />
      </>
    );

    const userRoutes = (
      <>
        <Route path="/assets" element={<AssetsOverview />} />
        <Route path="/assets/new" element={<AssetCreate />} />
        <Route path="/assets/new/mass-import" element={<AssetMassImport />} />
        <Route path="/assets/:id" element={<AssetViewEditPageParent />}>
          <Route path="equipment/new" element={<EquipmentCreate />} />
          <Route
            path="equipment/mass-import"
            element={<EquipmentMassImport />}
          />
          <Route
            path="equipment/:equipmentId"
            element={<EquipmentViewEditParent />}
          >
            <Route path="general" element={<EquipmentViewEdit />} />
            <Route
              path="maintenance/new"
              element={<MaintenanceCreate facilityType="equipment" />}
            />
            <Route
              path="maintenance/:maintenanceId"
              element={<MaintenanceViewEdit facilityType="equipment" />}
            />
            <Route
              path="maintenance/:maintenanceId/history/:historyId"
              element={<MaintenanceHistoryViewEdit />}
            />
            <Route
              path="maintenance/:maintenanceId/history/new"
              element={<MaintenanceHistoryCreate />}
            />
            <Route
              path="maintenance"
              element={<MaintenanceOverview facilityType="equipment" />}
            />
            <Route
              path="certification/new"
              element={<CertificationCreate facilityType="equipment" />}
            />
            <Route
              path="certification/:certificationId"
              element={<CertificationViewEdit facilityType="equipment" />}
            />
            <Route
              path="certification/:certificationId/history/:historyId"
              element={<CertificationHistoryViewEdit />}
            />
            <Route
              path="certification/:certificationId/history/new"
              element={<CertificationHistoryCreate />}
            />
            <Route
              path="certification"
              element={<CertificationOverview facilityType="equipment" />}
            />
          </Route>
          <Route path="equipment" element={<EquipmentOverview />} />
          <Route
            path="maintenance/new"
            element={<MaintenanceCreate facilityType="asset" />}
          />
          <Route
            path="maintenance/:maintenanceId"
            element={<MaintenanceViewEdit facilityType="asset" />}
          />
          <Route
            path="maintenance/:maintenanceId/history/:historyId"
            element={<MaintenanceHistoryViewEdit />}
          />
          <Route
            path="maintenance/:maintenanceId/history/new"
            element={<MaintenanceHistoryCreate />}
          />
          <Route
            path="maintenance"
            element={<MaintenanceOverview facilityType="asset" />}
          />
          <Route
            path="certification/new"
            element={<CertificationCreate facilityType="asset" />}
          />
          <Route
            path="certification/:certificationId"
            element={<CertificationViewEdit facilityType="asset" />}
          />
          <Route
            path="certification/:certificationId/history/:historyId"
            element={<CertificationHistoryViewEdit />}
          />
          <Route
            path="certification/:certificationId/history/new"
            element={<CertificationHistoryCreate />}
          />
          <Route
            path="certification"
            element={<CertificationOverview facilityType="asset" />}
          />
          <Route path="contracts/new" element={<AssetContractCreate />} />
          <Route
            path="contracts/:contractId"
            element={<AssetContractViewEdit />}
          />
          <Route path="contracts" element={<AssetContractsOverview />} />
          <Route path="reports" element={<NoContentPage />} />
          <Route path="rents/new" element={<RentsCreate />} />
          <Route
            path="rents/history/:historyId"
            element={<RentHistoryViewEdit />}
          />
          <Route path="rents/history/new" element={<RentHistoryCreate />} />
          <Route path="rents" element={<RentOverview />} />
          <Route path="" element={<AssetViewEdit />} />
        </Route>
        <Route
          path="/companies/:id/contracts/:contractId"
          element={<CompanyContractViewEdit />}
        />
        <Route path="/companies/:id" element={<CompanyViewEdit />} />
        <Route path="/companies" element={<CompaniesOverview />} />
        <Route path="/contracts/:contractId" element={<ContractViewEdit />} />
        <Route path="/contracts" element={<ContractsOverview />} />
        <Route path="/tickets/new" element={<TicketCreate />} />
        <Route path="/tickets/:id" element={<TicketViewEdit />} />
        <Route path="/tickets" element={<TicketsOverview />} />
        <Route path="/tasks" element={<TasksOverview />} />
        <Route path="/tasks/:taskId" element={<TasksViewEdit />} />
      </>
    );

    const adminRoutes = (
      <>
        <Route path="/companies/new" element={<CompanyCreate />} />
        <Route
          path="/companies/:id/contracts/new"
          element={<ContractCreate />}
        />
        <Route path="/contracts/new" element={<ContractCreate />} />
        <Route path="/technical-issues" element={<TicketsOverview />} />
        <Route path="/technical-issues/:id" element={<TicketViewEdit />} />
        <Route path="/customize" element={<CustomizationParent />}>
          <Route path="equipment" element={<EquipmentConfiguration />}>
            <Route path="groups" element={<EquipmentGroupsOverview />} />
            <Route path="types" element={<EquipmentTypesOverview />} />
            <Route path="classes" element={<EquipmentClassesOverview />} />
          </Route>
          <Route path="certification" element={<CertificationConfiguration />}>
            <Route path="types" element={<CertificationTypesOverview />} />
            <Route path="classes" element={<CertificateClassesOverview />} />
          </Route>
          <Route path="maintenance" element={<MaintenanceConfiguration />}>
            <Route path="types" element={<MaintenanceTypesOverview />} />
          </Route>
        </Route>
      </>
    );

    const managerRoutes = (
      <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/reports" element={<Reports />} />
      </>
    );

    const superAdminRoutes = (
      <>
        <Route path="/users/new" element={<UserCreate />} />
        <Route path="/users/:id" element={<UserViewEdit />} />
        <Route path="/users" element={<UsersOverview />} />
      </>
    );

    const technicalIssuesRoutes = (
      <>
        <Route path="/technical-issues" element={<TicketsOverview />} />
        <Route path="/technical-issues/:id" element={<TicketViewEdit />} />
      </>
    );

    if (authStore.isAdmin) {
      return (
        <>
          {adminRoutes}
          {managerRoutes}
          {userRoutes}
          {defaultRoutes}
        </>
      );
    }

    if (authStore.isManager) {
      return (
        <>
          {managerRoutes}
          {defaultRoutes}
        </>
      );
    }

    if (authStore.isSuperAdmin) {
      return (
        <>
          {superAdminRoutes}
          {adminRoutes}
          {managerRoutes}
          {userRoutes}
          {defaultRoutes}
        </>
      );
    }

    if (authStore.isTechnicalIssues) {
      return (
        <>
          {technicalIssuesRoutes}
          {defaultRoutes}
        </>
      );
    }

    if (authStore.isUser) {
      return (
        <>
          {userRoutes}
          {defaultRoutes}
        </>
      );
    }

    return defaultRoutes;
  };

  return (
    <Routes>
      {authStore.isUserAuthenticated ? (
        getRoleBasedRoutes()
      ) : (
        <Route path="/*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
};

export default observer(AuthenticatedRoutes);
