import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import DeleteButton from "components/buttons/DeleteButton";
import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import SaveButton from "components/buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextField from "components/inputs/CustomTextField";
import colors from "config/theme/colors";
import { useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import DropdownField from "components/inputs/DropdownField";
import { useGetAllEquipmentGroups } from "hooks/queries/useEquipmentGroup";
import SpinningCircle from "components/spinners/SpinningCircle";
import DeleteModal from "components/modals/DeleteModal";
import ExitFormModal from "components/modals/ExitFormModal";

const EquipmentClassForm = ({
  isCreateForm = false,
  selectedEquipmentClass,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: equipmentGroupOptions,
    isLoading: equipmentGroupsLoading,
    error: equipmentGroupsError,
  } = useGetAllEquipmentGroups(
    {
      pageNumber: -1,
      pageSize: -1,
    },
    {
      select: (data) => data?.result?.items,
    }
  );

  const isCreatingLoading =
    useIsMutating({
      mutationKey: ["createEquipmentClass"],
    }) > 0;
  const isUpdateLoading =
    useIsMutating({
      mutationKey: ["updateEquipmentClass"],
    }) > 0;
  const isDeleteLoading =
    useIsMutating({
      mutationKey: ["deleteEquipmentClass"],
    }) > 0;

  const schema = yup.object({
    name: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    description: yup.string().nullable(),
    equipmentGroupId: yup
      .object()
      .shape({
        id: yup.number().required("Το πεδίο είναι υποχρεωτικό"),
        label: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
      })
      .required("Το πεδίο είναι υποχρεωτικό"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const defaultValues = selectedEquipmentClass
      ? {
          name: selectedEquipmentClass.name || "",
          description: selectedEquipmentClass.description || "",
          equipmentGroupId: {
            id: selectedEquipmentClass.equipmentGroup?.id,
            label: selectedEquipmentClass.equipmentGroup?.name,
          },
        }
      : {
          name: "",
          description: "",
          equipmentGroupId: null,
        };
    reset(defaultValues);
  }, [selectedEquipmentClass, reset, equipmentGroupOptions]);

  if (equipmentGroupsLoading) {
    return <SpinningCircle />;
  }

  if (equipmentGroupsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {equipmentGroupsError.message}</p>
      </Box>
    );
  }

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const disabled = !isEditing && !isCreateForm;

  return (
    <>
      <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography fontSize={24} fontWeight={600} color={colors.secondary}>
            {isCreateForm
              ? "Δημιουργία Κλάσης"
              : `${selectedEquipmentClass?.name} - ${isEditing ? "Επεξεργασία" : "Προβολή"}`}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                name="name"
                label="Όνομα"
                type="text"
                value={selectedEquipmentClass?.name}
                control={control}
                disabled={disabled}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                name="description"
                label="Περιγραφή"
                type="textarea"
                value={selectedEquipmentClass?.description}
                control={control}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownField
                name="equipmentGroupId"
                label="Ομάδα Εξοπλισμού"
                control={control}
                disabled={disabled}
                editable={isCreateForm}
                required
                value={selectedEquipmentClass?.equipmentGroupId}
                options={equipmentGroupOptions?.map((item) => ({
                  id: item.id,
                  label: item.name,
                }))}
              />
            </Grid>
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              {isCreateForm && isDirty && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                  disabled={disabled}
                />
              )}
              {!isCreateForm && (
                <CancelButton
                  onClick={() => {
                    reset();
                    onCancel();
                  }}
                />
              )}
              {!isCreateForm && isEditing && (
                <DeleteButton
                  onClick={handleOpenModal}
                  text="Διαγραφή Κλάσης"
                  disabled={disabled}
                  isLoading={isDeleteLoading}
                />
              )}
            </Box>

            {(isEditing || isCreateForm) && (
              <SaveButton
                disabled={disabled || !isDirty}
                text={isCreateForm ? "Δημιουργία" : "Αποθήκευση"}
                isLoading={isUpdateLoading || isCreatingLoading}
              />
            )}

            {isEditing && (
              <DeleteModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onDelete={onDelete}
                itemTitle={selectedEquipmentClass?.name}
                title="Πρόκειται να διαγράψετε την κλάση"
                description="Σιγουρευτείτε ότι η συγκεκριμένη κλάση δεν συσχετίζεται με κάποιον εξοπλισμό. Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή της κλάσης;"
              />
            )}
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

EquipmentClassForm.propTypes = {
  isCreateForm: PropTypes.bool,
  selectedEquipmentClass: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default EquipmentClassForm;
