import colors from "config/theme/colors";

export const getTaskBackgroundColor = (status) => {
  switch (status) {
    case "Νέο":
      return colors.info;
    case "Εκκρεμεί":
      return colors.warning;
    case "Ολοκληρωμένο":
      return colors.successBg;
    case "Ληξιπρόθεσμο":
      return colors.red;
    default:
      return colors.primary;
  }
};
