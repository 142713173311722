import { Box } from "@mui/material";
import PropTypes from "prop-types";
import MassAssetImportUploader from "../MassAssetImportUploader";

const requiredHeaders = [
  "Όνομα",
  "ΚΑΕΚ",
  "Όροφοι",
  "Πόλη",
  "Διεύθυνση",
  "ΤΚ",
  "Επιφάνεια ακινήτου",
  "Ιδιοκτησία",
  "Χρήση ακινήτου",
  "Κατηγορία",
];

const UploadFileStep = ({ onUpload }) => {
  const handleFileUpload = (json) => {
    onUpload(json);
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <MassAssetImportUploader
        onFileUpload={handleFileUpload}
        requiredHeaders={requiredHeaders}
      />
    </Box>
  );
};

UploadFileStep.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

export default UploadFileStep;
