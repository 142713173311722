const colors = {
  primary: "#524EFF",
  taskNew: "#00008B",
  secondary: "#FF7A00",
  error: "#f44336",
  red: "#FF000070",
  taskOverdue: "#DF0404",
  taskPending: "#d16502",
  errorBg: "#FFC5C54D",
  filterTitle: "#747A80",
  filterCloseIcon: "#524EFF",
  filterCheckboxCheckedIcon: "#524EFF",
  filterCheckBoxBorder: "#D8D8D8",
  filterModalLabel: "#747480",
  filterGroupsParentBox: "#E1E1E2",
  filterChecked: "#5C58FF",
  background: "#f4f5fd",
  greyBackground: "#EBF0F0",
  warning: "#ffb74d",
  info: "#29b6f6",
  success: "#008767",
  successBg: "#9EDFD04D",
  black: "#2F2F2F",
  grey: "#747A80",
  lightGrey: "#BEBEBE",
  lightGreyBg: "#F1F2F4",
  greyWithOpacity: "#FFFCFC26",
  text: "#242424",
  disabledInputBackground: "#FBFBFB",
  disabledInputBorder: "#CFDBD5B2",
  white: "#ffffff",
  boxShadowColor: "#DCE0F980",
  primaryLight: "#524EFF5E",
};

export default colors;
