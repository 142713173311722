import React, { useCallback, useEffect, useState } from "react";
import { Box, Icon, IconButton, Typography } from "@mui/material";
import colors from "config/theme/colors";
import { Menu, Sidebar } from "react-pro-sidebar";
import { ReactComponent as AssetsIcon } from "assets/icons/assets.svg";
import { ReactComponent as CompaniesIcon } from "assets/icons/companies.svg";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { ReactComponent as TicketsIcon } from "assets/icons/tickets.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { ReactComponent as AlertsIcon } from "assets/icons/alerts.svg";
import { ReactComponent as CustomizeIcon } from "assets/icons/customize.svg";
import { ReactComponent as TechnicalIcon } from "assets/icons/technical.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import SidebarItem from "./SidebarItem";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import theme from "config/theme/theme";
import { useSidebar } from "hooks/useSidebar";
import { observer } from "mobx-react-lite";
import { authStore } from "stores/AuthStore";
import useNotifications from "hooks/useNotifications";

const SidebarMenu = () => {
  const { isCollapsed, toggleSidebar } = useSidebar();
  const [selected, setSelected] = useState("");
  const [autoToggled, setAutoToggled] = useState(false);
  const {
    counts: { newTasks: newTasksCount, newTickets: newTicketsCount },
  } = useNotifications();

  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    const shouldCollapse = screenWidth < theme.breakpoints.values.md;
    const shouldExpand = screenWidth >= theme.breakpoints.values.lg;

    if (
      !autoToggled &&
      ((shouldCollapse && !isCollapsed) || (shouldExpand && isCollapsed))
    ) {
      toggleSidebar();
      setAutoToggled(true);
    } else if (!shouldCollapse && !shouldExpand) {
      setAutoToggled(false);
    }
  }, [isCollapsed, toggleSidebar, autoToggled]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const renderLogoAndTitle = () => {
    return (
      <div
        className={`flex items-center justify-center ${isCollapsed ? "flex-col" : "flex-row"}`}
      >
        <IconButton
          sx={{
            color: colors.primary,
            marginTop: isCollapsed ? "10px" : "0",
          }}
          onClick={toggleSidebar}
        >
          <MenuOutlinedIcon />
        </IconButton>
        <div className="flex items-center justify-center p-4 cursor-pointer">
          <LogoIcon
            fill={colors.primary}
            className={isCollapsed ? "" : "mr-2"}
            width={44}
            height={44}
          />
          {!isCollapsed && (
            <div className="flex flex-col pt-2">
              <Typography
                fontWeight={900}
                fontSize={21}
                color={colors.black}
                lineHeight={"25.4px"}
                letterSpacing={"0.25px"}
              >
                Facility
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={8}
                textTransform={"uppercase"}
                color={colors.lightGrey}
                textAlign={"right"}
                paddingLeft={1}
              >
                Manager
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSuperAdminMenu = () => {
    return (
      <>
        {!isCollapsed ? (
          <Typography
            fontSize={12}
            fontWeight={600}
            textTransform={"uppercase"}
            color={colors.text}
            sx={{ m: "15px 0 5px 20px" }}
          >
            <span lang="el">Επιλογές Super Admin</span>
          </Typography>
        ) : (
          <hr
            style={{
              color: colors.grey,
              backgroundColor: colors.grey,
              height: 1,
              width: "80%",
              margin: "0 auto",
            }}
          />
        )}

        <SidebarItem
          title="Διαχείριση Χρηστών"
          to="/users"
          icon={
            <UsersIcon
              width={22}
              height={22}
              fill={colors.greyBackground}
              stroke={
                selected === "Διαχείριση Χρηστών" ? colors.primary : colors.grey
              }
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />
      </>
    );
  };

  const renderAdminMenu = () => {
    return (
      <>
        {!isCollapsed ? (
          <Typography
            fontSize={12}
            fontWeight={600}
            textTransform={"uppercase"}
            color={colors.text}
            sx={{ m: "15px 0 5px 20px" }}
          >
            <span lang="el">Επιλογές Διαχειριστή</span>
          </Typography>
        ) : (
          <hr
            style={{
              color: colors.grey,
              backgroundColor: colors.grey,
              height: 1,
              width: "80%",
              margin: "0 auto",
            }}
          />
        )}

        <SidebarItem
          title="Παραμετροποίηση"
          to="/customize"
          icon={
            <CustomizeIcon
              width={22}
              height={22}
              fill={
                selected === "Παραμετροποίηση" ? colors.primary : colors.grey
              }
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />
      </>
    );
  };

  const renderTechnicalIssuesMenu = () => {
    return (
      <>
        {!isCollapsed ? (
          <Typography
            fontSize={12}
            fontWeight={600}
            textTransform={"uppercase"}
            color={colors.text}
            sx={{ m: "15px 0 5px 20px" }}
          >
            <span lang="el">Επιλογές Τεχνικού Υπεύθυνου</span>
          </Typography>
        ) : (
          <hr
            style={{
              color: colors.grey,
              backgroundColor: colors.grey,
              height: 1,
              width: "80%",
              margin: "0 auto",
            }}
          />
        )}

        <SidebarItem
          title="Τεχνικά Αιτήματα"
          to="/technical-issues"
          icon={
            <TechnicalIcon
              width={22}
              height={22}
              fill={
                selected === "Τεχνικά Αιτήματα" ? colors.primary : colors.grey
              }
              stroke={
                selected === "Τεχνικά Αιτήματα" ? colors.primary : colors.grey
              }
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
          isNotificationOn={newTicketsCount > 0}
        />
      </>
    );
  };

  const renderManagerMenu = () => {
    return (
      <>
        <SidebarItem
          title="Κεντρική"
          to="/dashboard"
          icon={
            <Icon
              component={HomeOutlinedIcon}
              sx={{
                color: selected === "Κεντρική" ? colors.primary : colors.grey,
                "&:hover": {
                  color: colors.primary,
                },
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />

        <SidebarItem
          title="Αναφορές"
          to="/reports"
          icon={
            <Icon
              component={SummarizeOutlinedIcon}
              sx={{
                color: selected === "Αναφορές" ? colors.primary : colors.grey,
                "&:hover": {
                  color: colors.primary,
                },
              }}
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />
      </>
    );
  };

  const renderUserMenu = () => {
    return (
      <>
        {!isCollapsed ? (
          <Typography
            fontSize={12}
            fontWeight={600}
            textTransform={"uppercase"}
            color={colors.text}
            sx={{ m: "15px 0 5px 20px" }}
          >
            <span lang="el">Διαχείριση Περιουσίας</span>
          </Typography>
        ) : (
          <hr
            style={{
              color: colors.grey,
              backgroundColor: colors.grey,
              height: 1,
              width: "80%",
              margin: "0 auto",
            }}
          />
        )}

        <SidebarItem
          title="Ακίνητα"
          to="/assets"
          icon={
            <AssetsIcon
              width={20}
              height={20}
              fill={selected === "Ακίνητα" ? colors.primary : colors.grey}
              color={selected === "Ακίνητα" ? colors.primary : colors.grey}
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />
        <SidebarItem
          title="Οργανισμοί"
          to="/companies"
          icon={
            <CompaniesIcon
              width={22}
              height={22}
              fill={selected === "Οργανισμοί" ? colors.primary : colors.grey}
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />
        <SidebarItem
          title="Συμβάσεις"
          to="/contracts"
          icon={
            <FolderOutlinedIcon
              width={22}
              height={22}
              fill={selected === "Συμβάσεις" ? colors.primary : colors.grey}
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
        />
        {!authStore.isSuperAdmin && !authStore.isAdmin && (
          <SidebarItem
            title="Τεχνικά Αιτήματα"
            to="/tickets"
            icon={
              <TicketsIcon
                width={22}
                height={22}
                fill={selected === "Αιτήματα" ? colors.primary : colors.grey}
              />
            }
            selected={selected}
            setSelected={setSelected}
            isCollapsed={isCollapsed}
          />
        )}
        <SidebarItem
          title="Εργασίες"
          to="/tasks"
          icon={
            <AlertsIcon
              width={22}
              height={22}
              fill={selected === "Εργασίες" ? colors.primary : colors.grey}
            />
          }
          selected={selected}
          setSelected={setSelected}
          isCollapsed={isCollapsed}
          isNotificationOn={newTasksCount > 0}
        />
      </>
    );
  };

  const renderMenuItemsBasedOnRole = () => {
    if (authStore.isUser) {
      return renderUserMenu();
    } else if (authStore.isTechnicalIssues) {
      return renderTechnicalIssuesMenu();
    } else if (authStore.isManager) {
      return renderManagerMenu();
    } else if (authStore.isAdmin) {
      return (
        <>
          {renderManagerMenu()}
          {renderUserMenu()}
          {renderTechnicalIssuesMenu()}
          {renderAdminMenu()}
        </>
      );
    } else if (authStore.isSuperAdmin) {
      return (
        <>
          {renderManagerMenu()}
          {renderUserMenu()}
          {renderTechnicalIssuesMenu()}
          {renderAdminMenu()}
          {renderSuperAdminMenu()}
        </>
      );
    }
  };

  return (
    <Box
      sx={{
        "& ul": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: "20px",
        },
      }}
    >
      <Sidebar
        collapsed={isCollapsed}
        className="fixed inset-y-0 z-50 h-screen"
        width="260px"
        rootStyles={{
          backgroundColor: colors.greyBackground,
        }}
      >
        <Menu
          iconShape="square"
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* LOGO AND MENU ICON */}
            {renderLogoAndTitle()}

            {/* MENU ITEMS */}
            {renderMenuItemsBasedOnRole()}
          </Box>

          <Box>
            <SidebarItem
              title="Ρυθμίσεις Προφίλ"
              to="/profile"
              icon={
                <ProfileIcon
                  width={22}
                  height={22}
                  fill={
                    selected === "Ρυθμίσεις Προφίλ"
                      ? colors.primary
                      : colors.grey
                  }
                  stroke={
                    selected === "Ρυθμίσεις Προφίλ"
                      ? colors.primary
                      : colors.grey
                  }
                />
              }
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
            />
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default observer(SidebarMenu);
